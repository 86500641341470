import { gql } from '@apollo/client';

export const GET_ASSETS = gql`
  query assets($filter: AssetsFilter, $sort: AssetsSort) {
    assets(filter: $filter, sort: $sort) {
      count
      assets {
        id
        title
        blurhash
        status
        description
        languageCode
        categoryKey
        serviceVideoThumbnail
        serviceImageThumbnail
        url
        createdAt
        reason
      }
    }
  }
`;

export const GET_ASSET = gql`
  query asset($where: AssetWhereUniqueInput!) {
    asset(where: $where) {
      id
      title
      description
      languageCode
      categoryKey
      serviceImageThumbnail
      url
      createdAt
      platform {
        id
        name
      }
    }
  }
`;

export const GET_UPLOAD_SIGNED_URL = gql`
  query getAssetUploadSignedUrl($data: GetAssetUploadSignedUrlInput!) {
    getAssetUploadSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;

export const GET_VIDEO_UPLOAD_SIGNED_URL = gql`
  query getVideoUploadSignedUrl($data: GetVideoUploadSignedUrlInput) {
    getVideoUploadSignedUrl(data: $data) {
      signedUrl
      uploadId
    }
  }
`;

export const GET_LANGUAGES = gql`
  query languages($filter: LanguageFilter, $sort: LanguageSort) {
    languages(filter: $filter, sort: $sort) {
      languages {
        id
        name
        code
      }
      count
    }
  }
`;

export const GET_VIDEOS_BY_WORKSPACE_PLATFORM = gql`
  query getVideosByWorkspacePlatform(
    $where: WorkspacePlatformWhereUniqueInput!
    $filter: VideosByPlatformFilter
    $sort: VideosByPlatformSort
  ) {
    getVideosByWorkspacePlatform(where: $where, filter: $filter, sort: $sort) {
      count
      platformVideos {
        id
        title
        description
        imageThumbnailUrl
        videoThumbnailUrl
      }
    }
  }
`;

export const GET_VIDEO_URL_BY_WORKSPACE_PLATFORM_VIDEO = gql`
  query getVideoUrlByWorkspacePlatformAndVideoId(
    $where: WorkspacePlatformVideoUniqueWhereInput!
  ) {
    getVideoUrlByWorkspacePlatformAndVideoId(where: $where) {
      id
      title
      url
    }
  }
`;

export const GET_ASSET_URL = gql`
  query asset($where: AssetWhereUniqueInput!) {
    asset(where: $where) {
      url
    }
  }
`;

export const GET_AUDIO_VIDEOS = gql`
  query assets($filter: AssetsFilter, $sort: AssetsSort) {
    assets(filter: $filter, sort: $sort) {
      count
      assets {
        id
        title
        blurhash
        status
        description
        languageCode
        categoryKey
        serviceVideoThumbnail
        serviceImageThumbnail
        createdAt
        reason
      }
    }
  }
`;
