import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Table } from 'antd';
import dayjs from 'dayjs';
import { CalendarBlank } from 'phosphor-react';
import React, { useCallback } from 'react';
import { defaultDateFormat } from '../common/constants';
import { GET_SUBSCRIPTION_PLANS } from '../modules/subscriptions-plans/graphql/Queries';
import { DatePicker, Select } from '../modules/videos/components/FormInputs';

const AllowedPlansTable = ({ loading, form, formKey }) => {
  const planColumns = useCallback(
    (fields) => [
      {
        title: 'Key',
        key: 'key',
        width: 50,
        render: (value, row) => {
          return (
            <Form.Item
              {...row}
              name={[row?.name, 'key']}
              rules={[
                {
                  validator() {
                    const keyValue = form.getFieldValue([
                      formKey,
                      row?.name,
                      'key'
                    ]);

                    if (!keyValue) {
                      return Promise.reject(new Error('Please select plan'));
                    }

                    const allValues = form.getFieldValue([formKey]);
                    const hasDuplicate =
                      allValues?.filter(
                        ({ key: selectedKey }) =>
                          selectedKey?.value === keyValue?.value
                      )?.length >= 2;
                    if (hasDuplicate) {
                      return Promise.reject(
                        new Error('Please select a unique plan')
                      );
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Select
                excludeOptions={['GUEST']}
                popupMatchSelectWidth={false}
                placeholder="Select plan"
                query={GET_SUBSCRIPTION_PLANS}
                variablesSelector={(filter) => ({
                  filter: {
                    isActive: true,
                    ...filter
                  }
                })}
                dataSelector={(res) =>
                  res?.subscriptionPlans?.subscriptionPlans?.map(
                    ({ key: dataKey }) => ({
                      label: dataKey,
                      value: dataKey
                    })
                  ) ?? 0
                }
                keys={{
                  data: 'subscriptionPlans',
                  records: 'subscriptionPlans',
                  count: 'count'
                }}
                onChange={() => {
                  fields.forEach(({ name }) => {
                    if (name !== row?.name) {
                      form.validateFields([[formKey, name, 'key']]);
                    }
                  });
                }}
              />
            </Form.Item>
          );
        }
      },
      {
        title: 'Expiry',
        key: 'expiredAt',
        render: (_, row) => {
          return (
            <Form.Item {...row} name={[row?.name, 'expiredAt']}>
              <DatePicker
                suffixIcon={<CalendarBlank size={20} />}
                disabledDate={(day) => dayjs().isAfter(day)}
                format={defaultDateFormat}
              />
            </Form.Item>
          );
        }
      }
    ],
    [form]
  );

  return (
    <Form.List name={[formKey]}>
      {(fields, { add, remove }) => (
        <div className="listview form-table">
          <Table
            dataSource={fields?.map((field) => field)}
            locale={{ emptyText: <div /> }}
            rowKey="key"
            loading={loading}
            pagination={false}
            bordered={false}
            footer={() => {
              return (
                <Button
                  onClick={() =>
                    add({
                      key: null,
                      expiredAt: null
                    })
                  }
                  icon={<PlusOutlined />}
                >
                  Add Plan
                </Button>
              );
            }}
            columns={[
              ...planColumns(fields),
              {
                title: 'Action',
                render: (value, row) => {
                  return (
                    <Button
                      className="text-btn"
                      type="text"
                      onClick={() => {
                        remove(row?.name);
                        fields.forEach(({ name }) => {
                          if (name !== row?.name) {
                            form.validateFields([[formKey, name, 'key']]);
                          }
                        });
                      }}
                      icon={<DeleteOutlined />}
                    />
                  );
                }
              }
            ]}
          />
        </div>
      )}
    </Form.List>
  );
};

export default AllowedPlansTable;
