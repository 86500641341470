import { Badge, Button, ConfigProvider } from 'antd';
import { CaretRight, Export, HeartStraight } from 'phosphor-react';
import React from 'react';
import podcastImg from '../../../assets/images/video-img.png';
import webStyle from '../../../styles/style-web.json';
import TagCarousel from '../tagModules/TagCarousel';

function PodcastInfo({
  category,
  categoryTagStyle = 'bg-yellow-2 text-n-1000',
  title,
  tags = [],
  description,
  responsive
}) {
  return (
    <ConfigProvider theme={webStyle}>
      <section
        className={`video-info-section ${responsive ? 'responsive-view' : ' '}`}
      >
        <div>
          <img src={podcastImg} alt="video dummy img" />
        </div>

        <div className="container">
          <div className="video-info-wrapper">
            <div className="videoinfo-title">
              {category ? (
                <Badge className={`badge-xs  ${categoryTagStyle}`}>
                  {category}
                </Badge>
              ) : null}

              {title ? <div className="display-h4">{title}</div> : null}
            </div>
            <div className="video-button-wrapper">
              <Button
                className="b-0 bg-n-400 text-n-900 text-small videoaction"
                icon={<Export size={24} weight="light" />}
              >
                Share
              </Button>
              <Button
                className="b-0 bg-n-400 text-n-900 text-small videoaction"
                icon={<HeartStraight size={24} weight="light" />}
              >
                Donate
              </Button>
            </div>

            {tags?.length > 0 ? (
              <div className="video-info-tags">
                <TagCarousel
                  className="text-small tag-s bg-n-400 b-0 text-n-900 mr-4"
                  data={tags}
                  icon={<CaretRight size={16} />}
                />
              </div>
            ) : null}
            {description ? <p className="paragraph">{description}</p> : null}
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}
export default PodcastInfo;
