import { gql } from '@apollo/client';

export const COMMON_ASSET_FIELDS = gql`
  fragment CommonAssetFields on Asset {
    id
    url
    blurhash
  }
`;

export const GET_PODCASTS_MODULE = gql`
  query PodcastsAdmin($filter: PodcastsFilter, $sort: PodcastsSort) {
    podcastsAdmin(filter: $filter, sort: $sort) {
      count
      podcasts {
        id
        title
        description
        createdAt
        imageThumbnail {
          url
          blurhash
        }
      }
    }
  }
`;

export const GET_PODCAST = gql`
  ${COMMON_ASSET_FIELDS}
  query PodcastAdmin($where: PodcastWhereUniqueInput!) {
    podcastAdmin(where: $where) {
      title
      description
      autoPublishAt
      displayDate
      slug
      tags {
        id
        name
      }
      topics {
        id
        name
      }
      status
      contentRating
      metaHeader
      metaFooter
      image {
        ...CommonAssetFields
      }
      imageThumbnail {
        ...CommonAssetFields
      }
      audio {
        ...CommonAssetFields
        title
      }
      video {
        ...CommonAssetFields
        title
      }
      previewAudio {
        id
        title
        url
      }
      previewVideo {
        id
        title
        url
      }
      descriptionHtml
      permissions
      code
      allowedPlans {
        key
        expiredAt
      }
    }
  }
`;

export const GET_QUESTION = gql`
  query QuestionsAdmin($filter: QuestionFilter!, $sort: QuestionsSort) {
    questionsAdmin(filter: $filter, sort: $sort) {
      count
      questions {
        id
        question
        isHidden
        isAccepted
        isHighlighted
        createdBy {
          id
          email
          uid
          authService
          authServiceId
          firstName
          lastName
          username
          profileImage
          permissions
          roles
          isActive
          metaData
          contactNumber
          countryCode
          zipCode
          address {
            addressLine1
            addressLine2
            state
            city
            country
          }
          createdAt
          updatedAt
          isEmailVerified
          isContactNumberVerified
          currentPlan
          assignedPlan
          effectivePlan
          assignedPlanExpiry
          oneTimePlans {
            key
            isActive
            createdAt
            expiredAt
          }
        }
        createdAt
      }
    }
  }
`;
