import { Modal } from 'antd';
import moment from 'moment';
import React, { useMemo, useRef } from 'react';
import {
  LIST_TYPES,
  PAGE_TYPES,
  defaultDateFormatWithTime
} from '../../../common/constants';
import { useFilterBar } from '../../../components/FilterBar';
import PageList from '../../../components/PageList';
import { GET_QUESTION } from '../graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  sortField,
  sortOrder,
  instanceId,
  instanceType
}) => ({
  filter: {
    skip: offset,
    limit,
    instanceId,
    instanceType
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ questionsAdmin }) => ({
  data: questionsAdmin?.questions ?? [],
  count: questionsAdmin?.count ?? 0
});

const QuestionModal = ({ open, setOpen, questionId }) => {
  const filterProps = useFilterBar();
  const filters = useMemo(
    () => ({
      ...filterProps.filters,
      instanceId: questionId,
      instanceType: PAGE_TYPES.PODCAST
    }),
    [filterProps.filters, questionId]
  );
  const questionRef = useRef(null);

  const columns = [
    {
      title: 'User',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (value) => (
        <div className="d-flex align-center">
          <p className="m-0">
            {value?.firstName} {value?.lastName}
          </p>
        </div>
      )
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question'
    },
    {
      title: 'Submitted',
      dataIndex: 'createdAt',
      render: (value) => moment(value).format(defaultDateFormatWithTime)
    }
  ];

  return (
    <Modal
      footer={null}
      className="question-modal"
      title={
        <div className="d-flex justify-between align-center">
          <p>Question</p>
        </div>
      }
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      width={1080}
      destroyOnClose
      centered
    >
      <div className="podcast-question-table">
        <PageList
          ref={questionRef}
          filters={filters}
          listMode={LIST_TYPES.TABLE}
          query={GET_QUESTION}
          variablesSelector={variablesSelector}
          dataSelector={dataSelector}
          TableProps={{ columns }}
        />
      </div>
    </Modal>
  );
};

export default QuestionModal;
