import { ConfigProvider } from 'antd';
import React from 'react';
import CtaCard from './ctaModule/CtaCard';
import Donate from './marketingComponent/donate/Donate';
import NewsLetter from './marketingComponent/newsLatter/NewsLetter';
import VideoInfo from './videoModule/VideoInfo';

const defaultColors = {
  neutral0: '#fff8e8',
  neutral100: '#fff6e6',
  neutral200: '#fef3e3',
  neutral300: '#fcf1e1',
  neutral400: '#f9eede',
  neutral500: '#7f7f7f',
  neutral600: '#666666',
  neutral700: '#4c4c4c',
  neutral800: '#333333',
  neutral900: '#191919',
  neutral1000: '#000000',
  ivory: '#f9eede',
  white90: '#eeeeee',
  white80: '#ebebeb',
  white70: '#e9e9e9',
  black90: '#1a1a1a',
  black80: '#333333',
  black70: '#4d4d4d',
  red: '#ff7b4c',
  red2: '#fe4819',
  red3: '#b02501',
  yellow: '#ffe546',
  yellow2: '#f4b213',
  yellow3: '#9a6f07',
  blue: '#e1fdff',
  blue2: '#aecacd',
  blue3: '#6fa1a6',
  moss: '#d7c895',
  moss2: '#a49562',
  moss3: '#655c3b',
  footerBg: '#000000'
};
const theme = {
  token: {
    colorPrimary: 'var(--tq-neutral-900)',
    headerBg: 'var(--tq-neutral-900)',
    fontSizeHeading1: 40,
    fontSizeHeading2: 32,
    fontSizeHeading3: 28,
    fontSizeHeading4: 24,
    fontSizeHeading5: 20,
    colorBgBase: 'var(--tq-neutral-300)',
    fontFamily: 'Founders Grotesk',
    wireframe: true,
    colorBgLayout: 'var(--tq-ivory)',
    colorTextBase: 'var(--text-black-80)'
  },
  components: {
    Menu: {
      colorItemText: 'var(--tq-neutral-0)',
      colorItemBg: 'var(--tq-neutral-900)',
      colorBgContainer: 'green',
      fontFamily: "'Founders Grotesk'",
      fontSize: 12
    },
    Form: {
      labelColor: 'var(--tq-text-black-90)'
    },
    Button: {
      controlOutline: '0',
      controlOutlineWidth: 0,
      paddingContentHorizontal: 24,
      paddingXS: 18,
      colorBgTextHover: 'inherit',
      controlHeightLG: 54,
      controlHeight: 48,
      controlHeightSM: 42,
      borderRadiusSM: 8,
      borderRadius: 8,
      colorBorder: 'var(--tq-neutral-900)',
      colorPrimaryHover: 'unset',
      colorText: 'var(--tq-neutral-900)',
      colorBgContainer: 'transparent'
    },
    Radio: {
      colorPrimary: 'var(--tq-neutral-900)',
      colorBorder: 'var(--tq-neutral-900)',
      colorBgBase: 'transparent',
      colorBgContainer: 'transparent',
      controlHeight: 45,
      colorText: 'var(--tq-black-90)'
    },
    Checkbox: {
      controlInteractiveSize: 18,
      borderRadiusSM: 0,
      colorBorder: 'var(--tq-neutral-800)',
      colorText: 'var(--tq-red)'
    },
    Switch: {
      colorText: 'var(--tq-neutral-900)',
      colorPrimary: 'var(--tq-neutral-900)',
      colorPrimaryHover: 'inharit',
      colorPrimaryBorder: 'var(--tq-neutral-800)',
      colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
      colorTextQuaternary: 'transparent',
      colorBgContainer: 'transparent',
      controlHeight: 16,
      colorWhite: 'var(--tq-neutral-0)'
    },
    DatePicker: {
      presetsMaxWidth: 358,
      colorLinkActive: '#0958d9',
      colorLink: 'var(--tq-neutral-900)',
      colorPrimary: 'var(--tq-neutral-900)',
      borderRadius: 0,
      borderRadiusXS: 0,
      presetsWidth: 358,
      borderRadiusLG: 0,
      boxShadowSecondary: 'none',
      colorBgContainer: 'var(--tq-neutral-0)FFF'
    },
    Tabs: {
      colorPrimary: 'var(--tq-neutral-900)',
      lineWidth: 0,
      lineWidthBold: 4,
      padding: 16
    },
    Tooltip: {
      borderRadius: 8,
      borderRadiusXS: 2,
      fontSize: 18,
      borderRadiusOuter: 0,
      controlHeight: 40,
      sizePopupArrow: 16
    },
    Slider: {
      railSize: 12,
      borderRadiusXS: 12,
      dotSize: 8,
      handleSize: 24,
      handleSizeHover: 22,
      colorPrimaryBorder: 'var(--tq-neutral-900)',
      colorBorderSecondary: 'var(--tq-neutral-0)FFF',
      colorBgContainer: 'var(--tq-neutral-0)fff',
      colorTextDescription: 'var(--tq-neutral-0)FFF',
      colorTextDisabled: 'var(--tq-neutral-0)FFF',
      colorFillContentHover: 'var(--tq-neutral-0)FFF',
      colorFillSecondary: 'var(--tq-neutral-200)',
      colorFillTertiary: 'var(--tq-neutral-0)',
      colorPrimaryBorderHover: 'var(--tq-neutral-800)'
    },
    Pagination: {
      colorBgContainer: 'var(--tq-neutral-900)',
      colorPrimary: 'var(--tq-neutral-0)',
      colorPrimaryHover: 'var(--tq-neutral-0)',
      colorBgTextActive: 'var(--tq-neutral-0)',
      colorText: 'var(--tq-neutral-0)',
      controlHeight: 42,
      borderRadius: 0,
      borderRadiusLG: 0,
      borderRadiusSM: 0,
      marginXS: 4,
      controlItemBgActiveDisabled: 'var(--tq-neutral-0)',
      colorTextDisabled: 'var(--tq-neutral-0)',
      colorBgContainerDisabled: 'var(--tq-neutral-900)'
    },
    Input: {
      fontSize: 20,
      colorBgContainer: 'var(--tq-neutral-100)',
      borderRadius: 8,
      colorBorder: 'var(--tq-border-bottom)',
      controlHeight: 44,
      controlOutline: '0',
      colorPrimary: 'var(--tq-neutral-900)',
      colorPrimaryHover: 'transparant',
      colorIconHover: 'var(--tq-neutral-900)',
      controlOutlineWidth: 0,
      colorTextTertiary: '',
      colorTextQuaternary: '',
      colorTextDescription: '',
      colorText: 'tranparant',
      colorIcon: 'rgba(0, 0, 0, 0.45)',
      colorPrimaryActive: 'var(--tq-neutral-800)',
      colorBgContainerDisabled: 'var(--tq-neutral-700)',
      colorTextDisabled: 'var(--tq-neutral-600)'
    },
    Message: {
      colorBgElevated: 'var(--tq-neutral-200)',
      paddingXS: 16,
      colorText: 'var(--tq-neutral-900)',
      fontSize: 20,
      fontSizeLG: 20,
      lineHeight: 1,
      controlHeightLG: 56,
      boxShadow:
        '0px 19px 8px rgba(78, 68, 54, 0.02), 0px 5px 7px rgba(78, 68, 54, 0.13)',
      fontFamily: '"Founders Grotesk"'
    },
    Select: {
      colorBgContainer: 'var(--tq-neutral-100)',
      colorBorder: 'var(--tq-border-bottom)',
      controlHeight: 44,
      colorPrimaryHover: 'transparant',
      controlOutline: '0',
      fontSize: 20
    },
    Typography: {
      colorText: 'inharit',
      colorTextHeading: 'inharit',
      fontWeightStrong: 400
    }
  }
};
function Components({ colors = defaultColors }) {
  return (
    <>
      <style>{`
  .mobile-view  {
    --tq-neutral-0: ${colors?.neutral0};
    --tq-neutral-100: ${colors?.neutral100};
    --tq-neutral-200: ${colors?.neutral200};
    --tq-neutral-300: ${colors?.neutral300};
    --tq-neutral-400: ${colors?.neutral400};
    --tq-neutral-500: ${colors?.neutral500};
    --tq-neutral-600: ${colors?.neutral600};
    --tq-neutral-700: ${colors?.neutral700};
    --tq-neutral-800: ${colors?.neutral800};
    --tq-neutral-900: ${colors?.neutral900};
    --tq-neutral-1000: ${colors?.neutral1000};
    --tq-footer-bg: ${colors?.footerBg};
    --tq-ivory: ${colors?.ivory};
    --tq-white-90: ${colors?.white90};
    --tq-white-80: ${colors?.white80};
    --tq-white-70: ${colors?.white70};
    --tq-black-90: ${colors?.black90};
    --tq-black-80: ${colors?.black80};
    --tq-black-70: ${colors?.black70};
    --tq-red: ${colors?.red};
    --tq-red-2: ${colors?.red2};
    --tq-red-3: ${colors?.red3};
    --tq-yellow: ${colors?.yellow};
    --tq-yellow-2: ${colors?.yellow2};
    --tq-yellow-3: ${colors?.yellow3};
    --tq-blue: ${colors?.blue};
    --tq-blue-2: ${colors?.blue2};
    --tq-blue-3: ${colors?.blue3};
    --tq-moss: ${colors?.moss};
    --tq-moss-2: ${colors?.moss2};
    --tq-moss-3: ${colors?.moss};
  }
        `}</style>

      <div className="mobile-view">
        <div className="mobile-wrapper">
          <div className="phone">
            <div className="screen">
              <ConfigProvider theme={theme}>
                <div className="mb-header">Header</div>
                <VideoInfo
                  responsive
                  title="Lorem Ipsum"
                  description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                  categoryTagStyle="bg-yellow"
                  category="category"
                />
                <NewsLetter
                  background="bg-yellow-2"
                  title="Subscribe to Video Service"
                  description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur."
                  responsive
                />
                <div className="p-20" />
                <Donate
                  title="Help Advance Good"
                  description="Lorem ipsum dolor sit amet consectetur. Dictum ullamcorper pretium turpis vitae tellus eget arcu amet faucibus.Lorem ipsum dolor sit amet consectetur.
"
                  responsive
                />
                <div className="p-20" />
                <CtaCard
                  background="bg-moss"
                  responsive
                  img="/images/feature-banner.png"
                  title="Cta Title Goes Here"
                  secondary="secondary"
                  primary="primary"
                  description="Lorem ipsum dolor sit amet consectetur. Turpis sed turpis vitae pellentesque. Sapien integer placerat in in sit id sit donec amet."
                />
                <div className="p-20" />
                <div className="p-8" />
                <div className="p-20" />
                <div className="mb-footer">footer</div>
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Components;
