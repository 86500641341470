import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import { X } from 'phosphor-react';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { authClient } from '../../apollo';
import { formValidatorRules } from '../../common/utils';
import ModalComponent from '../../components/ModalComponent';
import './auth.less';
import { CHANGE_PASSWORD } from './graphql/Mutations';

const { required, password } = formValidatorRules;

const ChangePasswordModal = ({ visible, setVisible }) => {
  const { dispatch } = useContext(AppContext);

  const [changePasswordMutate, { loading }] = useMutation(CHANGE_PASSWORD, {
    client: authClient,
    onCompleted() {
      setVisible(false);
    }
  });

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = async ({ oldPassword, password: newPassword }) => {
    try {
      const response = await changePasswordMutate({
        variables: {
          data: {
            oldPassword,
            newPassword
          }
        }
      });
      if (response?.data?.changePasswordAdmin) {
        dispatch({ type: 'LOGOUT' });
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <ModalComponent
      title="Change Password"
      open={visible}
      onOk={handleOk}
      closeIcon={<X className="text-n-100" size={16} weight="bold" />}
      onCancel={handleCancel}
      footer={null}
      wrapClassName="change-password-modal"
    >
      <Form name="change-password" onFinish={onFinish}>
        <Form.Item
          name="oldPassword"
          rules={[required('Please enter old password!')]}
          normalize={(value) => value?.split(' ')?.join('')}
        >
          <Input.Password
            className="password-input"
            prefix={<LockOutlined className="icon-color" />}
            placeholder="Old password"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[required('Please enter password!'), password]}
          normalize={(value) => value?.split(' ')?.join('')}
        >
          <Input.Password
            className="password-input"
            prefix={<LockOutlined className="icon-color" />}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          normalize={(value) => value?.split(' ')?.join('')}
          rules={[
            required('Please enter confirm password!'),
            password,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && value !== getFieldValue('password')) {
                  return Promise?.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                }
                return Promise?.resolve();
              }
            })
          ]}
        >
          <Input.Password
            className="password-input"
            prefix={<LockOutlined className="icon-color" />}
            placeholder="Confirm password"
          />
        </Form.Item>
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                loading={loading}
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
              >
                ChangePassword
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="text"
                className="text-btn2"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </ModalComponent>
  );
};

export default ChangePasswordModal;
