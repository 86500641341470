import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space
} from 'antd';
import dayjs from 'dayjs';
import { capitalize, isObject, map } from 'lodash';
import { CalendarBlank } from 'phosphor-react';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_SCHEDULED,
  STATUS_TYPES,
  UNPUBLISHED_STATUS,
  VIDEO_RESOURCES_TYPES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION,
  defaultDateFormat,
  defaultDateFormatWithTime
} from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import { GET_TAGS, GET_TOPICS, GET_VIDEO } from './graphql/Queries';

import AudioPlaceholder from '../../assets/images/audioPlaceholder.jpg';
import { formValidatorRules } from '../../common/utils';
import AllowedPlansTable from '../../components/AllowedPlansTable';
import HtmlEditor from '../../components/HtmlEditor';
import useCheckPermission from '../../hooks/useCheckPermission';
import SelectAsset, { initialAsset } from '../assets/components/SelectAsset';
import { SlugInput } from '../labels/topics/components/FormInputs';
import { Permissions } from '../pages/component/pageModules/moduleForms/FormInputs';
import { Select } from './components/FormInputs';
import { CREATE_VIDEO, UPDATE_VIDEO } from './graphql/Mutations';

const initialResources = {
  [VIDEO_RESOURCES_TYPES.QUESTIONS]: '',
  [VIDEO_RESOURCES_TYPES.LINKS]: ''
};
const initialValues = {
  title: '',
  description: '',
  slug: '/',
  tags: [],
  topics: [],
  status: STATUS_TYPES.DRAFT,
  contentRating: '',
  video: {
    ...initialAsset
  },
  image: {
    ...initialAsset
  },
  imageThumbnail: {
    ...initialAsset
  },
  videoThumbnail: {
    ...initialAsset
  },
  timelineThumbnail: {
    ...initialAsset
  },
  videoAsset: {
    ...initialAsset
  },
  documents: [],
  audios: [],
  tracks: [],
  metaHeader: '',
  metaFooter: '',
  resources: {
    ...initialResources
  },
  html: '',
  permissions: []
};

const resources = [
  { label: 'Questions', name: VIDEO_RESOURCES_TYPES.QUESTIONS },
  { label: 'Links', name: VIDEO_RESOURCES_TYPES.LINKS }
];

const VIDEO_DESCRIPTION_MAX_LENGTH = 2000;

const AddEditVideo = ({ history, match: { params } }) => {
  const [form] = Form.useForm();
  const { videoId } = params;
  const isEdit = !!videoId;

  const [fetchVideoDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_VIDEO,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateVideo, { loading }] = useMutation(
    isEdit ? UPDATE_VIDEO : CREATE_VIDEO
  );

  const handleChangeDate = (event) => {
    if (event) {
      form.setFieldsValue({
        status: capitalize(STATUS_SCHEDULED)
      });
    } else {
      form.setFieldsValue({
        status: STATUS_TYPES.DRAFT
      });
    }
  };

  useEffect(() => {
    if (isEdit && !!videoId) {
      fetchVideoDetails({
        variables: {
          id: videoId
        }
      }).then((res) => {
        const video = res.data?.videoAdmin;
        if (video) {
          const slug = video?.slug.startsWith('/')
            ? video?.slug
            : `/${video?.slug}`;
          form.setFieldsValue({
            title: video?.title ?? '',
            description: video?.description ?? '',
            slug,
            displayDate: video?.displayDate ? dayjs(video?.displayDate) : null,
            autoPublishAt: video?.autoPublishAt
              ? dayjs(video?.autoPublishAt)
              : null,
            permissions:
              video?.permissions?.map((value) => ({
                label: value,
                value
              })) ?? [],
            tags:
              video?.tags?.map((tag) => ({
                label: tag?.name,
                value: tag?.id
              })) ?? [],
            topics:
              video?.topics?.length > 0
                ? map(video?.topics, (item) => {
                    return {
                      value: item?.id,
                      label: item?.name
                    };
                  })
                : [],
            status:
              video?.status === STATUS_SCHEDULED
                ? capitalize(STATUS_SCHEDULED)
                : video?.status ?? STATUS_TYPES.DRAFT,
            contentRating: video?.contentRating ?? '',
            video: {
              id: video?.video?.id ?? '',
              url:
                video?.video?.serviceVideoThumbnail ??
                video?.video?.serviceImageThumbnail ??
                ''
            },
            previewVideo: video?.previewVideo
              ? {
                  id: video?.previewVideo?.id,
                  url:
                    video?.previewVideo?.serviceVideoThumbnail ??
                    video?.previewVideo?.serviceImageThumbnail ??
                    '',
                  title: video?.previewVideo?.title
                }
              : {},
            image: {
              id: video?.image?.id ?? '',
              url: video?.image?.url ?? ''
            },
            imageThumbnail: {
              id: video?.imageThumbnail?.id ?? '',
              url: video?.imageThumbnail?.url ?? ''
            },
            videoThumbnail: {
              id: video?.videoThumbnail?.id ?? '',
              url: video?.videoThumbnail?.url ?? ''
            },
            timelineThumbnail: {
              id: video?.timelineThumbnail?.id ?? '',
              url: video?.timelineThumbnail?.url ?? ''
            },
            documents:
              video?.documents?.map(({ id, url, title }) => ({
                id,
                url,
                title
              })) ?? [],
            html: video?.html ?? '',
            audios:
              video?.audioTracks?.map(
                ({ id, serviceImageThumbnail, title }) => ({
                  id,
                  url: serviceImageThumbnail ?? AudioPlaceholder,
                  title
                })
              ) ?? [],
            tracks:
              video?.textTracks?.map(({ id, url, title }) => ({
                id,
                url,
                title
              })) ?? [],
            metaHeader: video?.metaHeader ?? '',
            metaFooter: video?.metaFooter ?? '',
            resources: (video?.resources ?? [])?.reduce(
              (acc, data) => {
                if (data?.content) {
                  acc[VIDEO_RESOURCES_TYPES[data?.type]] = data?.content;
                }
                return acc;
              },
              { ...initialResources }
            ),
            allowedPlans:
              video?.allowedPlans?.map((item) => ({
                ...item,
                key: { label: item?.key, value: item?.key },
                expiredAt: item?.expiredAt ? dayjs(item?.expiredAt) : null
              })) ?? []
          });
        }
      });
    }
  }, [isEdit, videoId, form, fetchVideoDetails]);

  const handleCancel = () => {
    history.replace(ROUTES.VIDEOS_MODULE);
  };

  const handleSubmit = (data) => {
    const payload = {
      title: data?.title || '',
      description: data?.description || '',
      autoPublishAt:
        data?.autoPublishAt && dayjs(data?.autoPublishAt).toISOString(),
      displayDate: data?.displayDate && dayjs(data?.displayDate).toISOString(),
      slug: data?.slug?.startsWith('/') ? data?.slug?.substring(1) : data?.slug,
      tags:
        data?.tags?.map((item, index) => {
          return {
            tagId: item?.value,
            order: index + 1
          };
        }) || [],
      topics:
        data?.topics?.length > 0
          ? map(data?.topics, (item, index) => {
              return {
                topicId: item?.value,
                order: index + 1
              };
            })
          : [],
      status:
        data?.status === capitalize(STATUS_SCHEDULED) ? null : data?.status,
      contentRating: data?.contentRating || '',
      imageId: data?.image?.id || null,
      imageThumbnailId: data?.imageThumbnail?.id || null,
      videoThumbnailId: data?.videoThumbnail?.id || null,
      timelineThumbnailId: data?.timelineThumbnail?.id || null,
      videoAsset: {
        id: data?.video?.id || null,
        previewId: data?.previewVideo?.id || null
      },
      audioTrackIds: data?.audios?.map(({ id }) => id),
      textTrackIds: data?.tracks?.map(({ id }) => id),
      html: data?.html ?? '',
      documentIds: data?.documents?.map(({ id }) => id),
      metaHeader: data?.metaHeader || '',
      metaFooter: data?.metaFooter || '',
      resources: Object.entries(VIDEO_RESOURCES_TYPES).map(([key, name]) => ({
        type: key,
        content: data?.resources?.[name] ?? ''
      })),
      permissions: data?.permissions?.map(({ value }) => value),
      allowedPlans: data?.allowedPlans?.map(({ key, expiredAt }) => ({
        key: isObject(key) ? key?.value : key,
        expiredAt: expiredAt ? dayjs(expiredAt) : null
      }))
    };

    addUpdateVideo({
      variables: {
        data: payload,
        ...(isEdit && {
          id: videoId
        })
      }
    }).then(() => {
      history.push(ROUTES?.VIDEOS_MODULE);
    });
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <>
      <PageHeader menu={MODULES?.CONTENT_UNITS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Row gutter={[16, 0]}>
              <Col md={24} lg={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter title!'),
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <Input
                    placeholder="Enter title"
                    onChange={handleTitleChange}
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(VIDEO_DESCRIPTION_MAX_LENGTH)
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
                <Form.Item
                  label="Slug"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter slug!'
                    },
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <SlugInput />
                </Form.Item>
                <Form.Item name="tags" label="Tags">
                  <Select
                    mode="multiple"
                    placeholder="Select tags"
                    query={GET_TAGS}
                    variablesSelector={(filter) => ({ filter })}
                    dataSelector={(data) =>
                      data?.tagsAdmin?.tags?.map(({ id, name }) => ({
                        label: name,
                        value: id
                      })) ?? []
                    }
                    keys={{
                      data: 'tagsAdmin',
                      records: 'tags',
                      count: 'count'
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="topics"
                  label="Topic"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (!value?.length) {
                          throw new Error('Please select topic!');
                        }
                      }
                    }
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select topic"
                    query={GET_TOPICS}
                    variablesSelector={(filter) => ({ filter })}
                    dataSelector={(data) =>
                      data?.topicsAdmin?.topics?.map(({ id, name }) => ({
                        label: name,
                        value: id
                      })) ?? 0
                    }
                    keys={{
                      data: 'topicsAdmin',
                      records: 'topics',
                      count: 'count'
                    }}
                  />
                </Form.Item>
                <Form.Item label="Status" name="status">
                  <AntdSelect
                    options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                      ({ name, value }) => ({
                        label: name,
                        value
                      })
                    )}
                    placeholder="Select status"
                  />
                </Form.Item>
                <Form.Item
                  name="contentRating"
                  label="Content Rating"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter content rating" />
                </Form.Item>

                <Form.Item
                  label="Video"
                  name="video"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (value.id === '') {
                          throw new Error('Please select video!');
                        }
                      }
                    }
                  ]}
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Video"
                    categoryKey={ASSET_CATEGORY.VIDEO}
                    btnText="Video"
                    dataSelector={({
                      id,
                      serviceImageThumbnail,
                      serviceVideoThumbnail
                    }) => ({
                      id,
                      url: serviceVideoThumbnail || serviceImageThumbnail
                    })}
                  />
                </Form.Item>
                <Form.Item label="Preview Video" name="previewVideo">
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Preview Video"
                    categoryKey={ASSET_CATEGORY.VIDEO}
                    btnText="Preview Video"
                    dataSelector={({
                      id,
                      serviceImageThumbnail,
                      serviceVideoThumbnail
                    }) => ({
                      id,
                      url: serviceVideoThumbnail || serviceImageThumbnail
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label="Image"
                  name="image"
                  extra="Recommended size  (2500 * 1242)"
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Image"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label="Image Thumbnail"
                  name="imageThumbnail"
                  extra="Recommended size  (590 * 330)"
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Image Thumbnail"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image Thumbnail"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label="Video Thumbnail"
                  name="videoThumbnail"
                  extra="Recommended size  (590 * 330)"
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Video Thumbnail"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Video Thumbnail"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>

                <Form.Item label="Text Tracks" name="tracks">
                  <SelectAsset
                    disabled={isViewOnly}
                    multiple
                    modalTitle="Select Tracks"
                    btnText="Tracks"
                    categoryKey={ASSET_CATEGORY.TEXT}
                    dataSelector={({ id, url, title }) => ({
                      id,
                      url,
                      title
                    })}
                  />
                </Form.Item>
                <Form.Item label="Audios" name="audios">
                  <SelectAsset
                    disabled={isViewOnly}
                    multiple
                    modalTitle="Select Audios"
                    btnText="Audios"
                    categoryKey={ASSET_CATEGORY.AUDIO}
                    dataSelector={({ id, serviceImageThumbnail, title }) => ({
                      id,
                      url: serviceImageThumbnail,
                      title
                    })}
                  />
                </Form.Item>
                <Form.Item label="Documents" name="documents">
                  <SelectAsset
                    disabled={isViewOnly}
                    multiple
                    modalTitle="Select Documents"
                    btnText="Documents"
                    categoryKey={ASSET_CATEGORY.DOCUMENT}
                    dataSelector={({ id, url, title }) => ({
                      id,
                      url,
                      title
                    })}
                  />
                </Form.Item>

                {resources.map(({ label, name }) => (
                  <Form.Item
                    key={name}
                    name={['resources', name]}
                    label={label}
                    rules={[
                      formValidatorRules?.maxLength(
                        4000,
                        'Max length of 4000 character exceeded'
                      )
                    ]}
                  >
                    <HtmlEditor disabled={isViewOnly} className="full-html" />
                  </Form.Item>
                ))}
                <Form.Item label="Display Date" name="displayDate">
                  <DatePicker
                    suffixIcon={<CalendarBlank size={20} />}
                    format={defaultDateFormat}
                  />
                </Form.Item>
                <Form.Item label="Auto Publish On" name="autoPublishAt">
                  <DatePicker
                    showTime
                    suffixIcon={<CalendarBlank size={20} />}
                    disabledDate={(day) => dayjs().isAfter(day, 'day')}
                    format={defaultDateFormatWithTime}
                    onChange={handleChangeDate}
                  />
                </Form.Item>

                <Form.Item
                  name="metaHeader"
                  label="Meta Header"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta header" />
                </Form.Item>

                <Form.Item
                  name="metaFooter"
                  label="Meta Footer"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta footer" />
                </Form.Item>
                <Permissions />
                <fieldset className="mb-12">
                  <legend className="role-legend">Allowed Plans</legend>
                  <AllowedPlansTable
                    loading={fetchingDetails}
                    form={form}
                    formKey="allowedPlans"
                  />
                </fieldset>
              </Col>
              <Col md={24} lg={12}>
                <Form.Item className="full-html" label="Html" name="html">
                  <HtmlEditor disabled={isViewOnly} className="full-html" />
                </Form.Item>
              </Col>
            </Row>
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditVideo;
