import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Select as AntdSelect, Button, Form, Input, Space, Table } from 'antd';
import { filter as lodashFilter, map } from 'lodash';
import { ArrowsOutCardinal } from 'phosphor-react';
import React, { forwardRef, useEffect, useMemo } from 'react';
import * as urlSlug from 'url-slug';
import { useApp } from '../../../AppContext';
import {
  ASSET_CATEGORY,
  COLLECTION_ITEM_TYPES,
  MAX_LENGTHS,
  MODULES,
  PAGE_TYPES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  UNPUBLISHED_STATUS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import PageHeader from '../../../components/PageHeader';
import useCheckPermission from '../../../hooks/useCheckPermission';
import SelectAsset from '../../assets/components/SelectAsset';
import { GET_FORMS } from '../../form/graphql/Queries';
import { Select } from '../../videos/components/FormInputs';
import { DEFAULT_CONFIG_KEYS } from '../../workspaces/components';
import { ColorPicker, SlugInput } from '../topics/components/FormInputs';
import { SelectPodcasts, SelectVideos } from './components/FormInputs';
import {
  CREATE_COLLECTION,
  UPDATE_COLLECTION,
  UPSERT_COLLECTION_ITEMS
} from './graphql/Mutations';
import { GET_COLLECTION } from './graphql/Queries';

const COLOR_KEYS = {
  neutral800: 'neutral800',
  blue2: 'blue2',
  moss2: 'moss2'
};

const initialValues = {
  title: '',
  description: '',
  slug: '/',
  status: STATUS_TYPES.DRAFT,
  thumbnail: {
    id: '',
    url: ''
  },
  primaryColor: COLOR_KEYS.neutral800,
  videoData: [],
  metaHeader: '',
  metaFooter: '',
  items: [{ type: 'VIDEO', itemId: null, sequence: null }]
};

const TableRow = forwardRef(({ children, ...rest }, ref) => (
  <tr ref={ref} {...rest}>
    {children}
  </tr>
));

const Draggable = ({ id, children, className, style: defaultStyles }) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging
  } = useSortable({
    id,
    strategy: verticalListSortingStrategy
  });

  const [firstChild, ...restChildren] = children;

  const style = transform
    ? {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS?.Translate?.toString(transform),
        transition
      }
    : undefined;

  const handlers = {
    ...attributes,
    ...listeners
  };

  return (
    <TableRow
      className={className}
      style={{ ...style, ...defaultStyles }}
      ref={setNodeRef}
    >
      {React.cloneElement(firstChild, {
        render: firstChild?.props?.render?.bind(null, handlers)
      })}
      {restChildren}
    </TableRow>
  );
};

const DraggableRow = ({ className, children, style, ...rest }) => {
  const id = rest['data-row-key'] + 1;
  if (!id)
    return (
      <TableRow className={className} style={style}>
        {children}
      </TableRow>
    );
  return (
    <Draggable id={id} className={className} style={style}>
      {children}
    </Draggable>
  );
};

const AddEditCollection = ({ history, match: { params } }) => {
  const { state } = useApp();
  const workspaceColors = state?.workspaceConfig?.find(
    ({ key }) => key === DEFAULT_CONFIG_KEYS.COLORS
  )?.value;
  const [form] = Form.useForm();
  const { collectionId } = params;
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);
  const items = Form.useWatch('items', form);
  const isAssetEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const isAssetViewAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);
  const isContentEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isEdit = !!collectionId;

  const colors = useMemo(
    () => [
      {
        key: COLOR_KEYS.neutral800,
        color: workspaceColors?.[COLOR_KEYS.neutral800]
      },
      {
        key: COLOR_KEYS.blue2,
        color: workspaceColors?.[COLOR_KEYS.blue2]
      },
      {
        key: COLOR_KEYS.moss2,
        color: workspaceColors?.[COLOR_KEYS.moss2]
      }
    ],
    [workspaceColors]
  );

  const [fetchCollectionDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_COLLECTION,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateCollection, { loading }] = useMutation(
    isEdit ? UPDATE_COLLECTION : CREATE_COLLECTION
  );

  const [upsertCollectionItems, { loading: itemsLoading }] = useMutation(
    UPSERT_COLLECTION_ITEMS,
    {
      onError() {}
    }
  );

  const getItemData = (data, type) => {
    switch (type) {
      case PAGE_TYPES.FORM:
        return {
          label: data?.title,
          value: data?.formId
        };
      case PAGE_TYPES.PODCAST:
        return {
          id: data?.podcastId,
          title: data?.title,
          url: data?.imageThumbnail?.url ?? ''
        };
      default:
        return {
          id: data?.videoId,
          title: data?.title,
          url: data?.videoThumbnail?.url ?? ''
        };
    }
  };

  useEffect(() => {
    if (isEdit && !!collectionId) {
      fetchCollectionDetails({
        variables: {
          id: collectionId,
          filter: {
            pagination: false
          }
        }
      })
        .then(({ data }) => {
          const collection = data?.collectionAdmin;
          const collectionItems = data?.collectionItemsAdmin?.collectionItems;
          if (collection) {
            const {
              title,
              description,
              slug,
              primaryColor,
              thumbnail,
              status,
              metaHeader,
              metaFooter
            } = collection;
            form.setFieldsValue({
              title,
              description,
              slug: slug.startsWith('/') ? slug : `/${slug}`,
              status,
              primaryColor,
              thumbnail: thumbnail ?? {
                id: '',
                url: ''
              },
              metaHeader: metaHeader ?? '',
              metaFooter: metaFooter ?? '',
              items: collectionItems?.map((item) => {
                return {
                  type: item?.type,
                  sequence: item?.sequence ?? null,
                  itemId: getItemData(item?.itemData, item?.type)
                };
              })
            });
          }
        })
        .catch();
    }
  }, [collectionId, isEdit, form]);

  const handleSubmit = ({
    slug,
    thumbnail,
    items: itemsData,
    ...restValues
  }) => {
    const payload = {
      slug: slug.startsWith('/') ? slug.substring(1) : slug,
      thumbnailId: thumbnail?.id || null,
      ...restValues
    };

    const collectionItems = itemsData?.map((item, index) => ({
      ...item,
      sequence: item?.sequence ?? null,
      itemId: item?.itemId?.id ?? item?.itemId?.value,
      order: index + 1
    }));

    addUpdateCollection({
      variables: {
        data: payload,
        ...(isEdit && {
          id: collectionId
        })
      }
    })
      .then(async (res) => {
        if (res?.data) {
          const response = await upsertCollectionItems({
            variables: {
              where: {
                id:
                  res?.data?.createCollection?.collection?.id ??
                  res?.data?.updateCollection?.collection?.id
              },
              data: {
                items: collectionItems
              }
            }
          });
          if (response?.data) {
            history.push(ROUTES?.COLLECTIONS);
          }
        }
      })
      .catch();
  };

  const handleCancel = () => {
    history.push(ROUTES?.COLLECTIONS);
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const getItems = (itemType) => {
    switch (itemType) {
      case PAGE_TYPES.PODCAST:
        return (
          <SelectPodcasts
            IsTableView
            disabled={isViewOnly}
            multiple={false}
            isContentEditAllowed={isContentEditAllowed}
          />
        );
      case PAGE_TYPES.FORM:
        return (
          <Select
            placeholder="Select form"
            query={GET_FORMS}
            disabled={isViewOnly || !isContentEditAllowed}
            variablesSelector={(filter) => ({
              filter: { ...filter, status: STATUS_TYPES.PUBLISHED }
            })}
            dataSelector={(data) =>
              data?.formsAdmin?.forms?.map(({ id, title }) => ({
                label: title,
                value: id
              })) ?? 0
            }
            allowClear
            keys={{
              data: 'formsAdmin',
              records: 'forms',
              count: 'count'
            }}
          />
        );
      default:
        return (
          <SelectVideos
            disabled={isViewOnly}
            multiple={false}
            isContentEditAllowed={isContentEditAllowed}
            IsTableView
          />
        );
    }
  };

  const isItemAlreadyPresent = (selectedItem, type) => {
    const filterItems = lodashFilter(items, (item) => item?.type === type);
    const getItem = [];
    map(filterItems, (item) => {
      if (
        (item?.itemId?.value && item?.itemId?.value === selectedItem?.value) ||
        (item?.itemId?.id && item?.itemId?.id === selectedItem?.id)
      ) {
        getItem?.push(item);
      }
    });
    if (getItem?.length > 1) {
      return true;
    }
    return false;
  };

  const collectionItemColumns = [
    {
      title: ' ',
      key: 'id',
      width: 30,
      render: (handlers) => {
        return (
          <div className="d-flex align-center justify-center">
            <ArrowsOutCardinal
              {...handlers}
              className="grab drag-icon"
              size={16}
            />
          </div>
        );
      }
    },
    {
      title: 'Type',
      key: 'type',
      width: 350,
      render: (value, row, index) => {
        return (
          <Form.Item
            {...row}
            name={[row?.name, 'type']}
            key={[row?.key, 'type']}
            className="mb-0"
            rules={[
              {
                required: true,
                message: 'Please select type!'
              }
            ]}
          >
            <AntdSelect
              options={COLLECTION_ITEM_TYPES}
              placeholder="Select Type"
              onChange={() => {
                form?.setFieldsValue({
                  items: {
                    [index]: {
                      itemId: null
                    }
                  }
                });
              }}
            />
          </Form.Item>
        );
      }
    },
    {
      title: 'Item',
      key: 'itemId',
      width: 350,
      render: (value, row, index) => {
        const getType = items?.[index]?.type;
        return (
          <Form.Item
            {...row}
            name={[row?.name, 'itemId']}
            key={[row?.key, 'itemId']}
            className="mb-0"
            rules={[
              {
                required: true,
                message: 'Please select item!'
              },
              () => ({
                validator(_, itemSelected) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  const isPresent = isItemAlreadyPresent(itemSelected, getType);
                  if (isPresent) {
                    return Promise.reject(new Error('Must Not Be Repeated'));
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            {getItems(getType)}
          </Form.Item>
        );
      }
    },
    {
      title: 'Sequence',
      key: 'sequence',
      width: 350,
      render: (_, row) => {
        return (
          <Form.Item
            {...row}
            name={[row?.name, 'sequence']}
            key={[row?.key, 'sequence']}
            className="mb-0"
          >
            <Input placeholder="Enter Sequence" />
          </Form.Item>
        );
      }
    }
  ];

  const handleDragEnd = ({ over, active }) => {
    if (!over) return;

    const source = active?.id - 1;
    const destination = over?.id - 1;
    if (source !== destination) {
      form.setFieldsValue({
        items: arrayMove(form.getFieldValue('items'), source, destination)
      });
    }
  };

  return (
    <>
      <PageHeader menu={MODULES?.LABELS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            className="add-edit-form"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
            disabled={isViewOnly || fetchingDetails}
          >
            <Form.Item
              label="Title"
              name="title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input placeholder="Enter title" onChange={handleTitleChange} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please enter slug!'
                },
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <SlugInput />
            </Form.Item>

            <Form.Item label="Status" name="status">
              <AntdSelect
                options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                  ({ name, value }) => ({
                    label: name,
                    value
                  })
                )}
                placeholder="Select status"
              />
            </Form.Item>

            <Form.Item label="Background Color" name="primaryColor">
              <ColorPicker data={colors} />
            </Form.Item>
            <Form.Item label="Main Image" name="thumbnail">
              <SelectAsset
                disabled={isViewOnly}
                modalTitle="Select Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
                isAssetEditAllowed={isAssetEditAllowed}
                isAssetViewAllowed={isAssetViewAllowed}
              />
            </Form.Item>
            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>
            <fieldset className="mb-12">
              <legend className="role-legend">Items</legend>
              <Form.List name={['items']}>
                {(fields, { add, remove }) => (
                  <div className="listview form-table nested-table">
                    <DndContext
                      sensors={sensors}
                      onDragEnd={handleDragEnd}
                      modifiers={[restrictToVerticalAxis]}
                    >
                      <SortableContext
                        strategy={verticalListSortingStrategy}
                        items={fields?.map(({ name }) => name + 1)}
                      >
                        <Button
                          onClick={() => add({ type: PAGE_TYPES.VIDEO }, 0)}
                          icon={<PlusOutlined />}
                          className="mb-20"
                        >
                          Add Item
                        </Button>
                        <Table
                          dataSource={fields?.map((field) => ({
                            ...field
                          }))}
                          rowKey="name"
                          loading={loading || itemsLoading}
                          pagination={false}
                          bordered={false}
                          components={{
                            body: {
                              row: DraggableRow
                            }
                          }}
                          columns={[
                            ...collectionItemColumns,
                            {
                              title: 'Action',
                              render: (value, row) => {
                                return (
                                  <Button
                                    disabled={fields?.length === 1}
                                    className="text-btn"
                                    type="text"
                                    onClick={() => {
                                      remove(row?.name);
                                    }}
                                    icon={<DeleteOutlined />}
                                  />
                                );
                              }
                            }
                          ]}
                        />
                      </SortableContext>
                    </DndContext>
                  </div>
                )}
              </Form.List>
            </fieldset>
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails || itemsLoading}
                    loading={loading || itemsLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}
                <Button
                  disabled={loading || itemsLoading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditCollection;
