import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Image, Space, theme, Typography } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getImageUrl } from '../../../common/utils';
import SelectableModal from '../../../components/SelectableModal';
import { SUBSCRIPTION_BADGES } from '../../subscriptions-plans/graphql/Queries';
import { useModal } from '../../videos/components/FormInputs';
import Preview from '../../videos/components/Preview';
import CreateAssetModal from './CreateAssetModal';

export const initialAsset = {
  id: '',
  url: ''
};

const SelectBadge = ({
  id,
  value,
  onChange,
  dataSelector,
  modalTitle,
  categoryKey,
  btnText,
  multiple = false,
  allowClear = true,
  isAssetEditAllowed = true,
  isAssetViewAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const { colorText } = theme.useToken().token;

  const { open, openModal, closeModal } = useModal();
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [newAsset, setNewAsset] = useState(null);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value.find((items) => items.id === item.id);
      if (isPresent) {
        onChange(value.filter((items) => items.id !== item.id));
      } else {
        onChange([...value, dataSelector(item)]);
      }
      return;
    }
    onChange(dataSelector(item));
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value.filter((items) => items.id !== item.id));
    } else {
      onChange({ ...initialAsset });
    }
  };

  useEffect(() => {
    if (!isEmpty(newAsset)) {
      if (newAsset?.asset) {
        if (multiple) {
          const isPresent = value.find(
            (items) => items.id === newAsset?.data.id
          );
          if (isPresent) {
            onChange(value.filter((items) => items.id !== newAsset?.data.id));
          } else {
            onChange([...value, dataSelector(newAsset?.data)]);
          }
        } else {
          onChange(dataSelector(newAsset?.data));
        }
      }
    }
  }, [newAsset]);

  return (
    <>
      {showAssetModal && (
        <CreateAssetModal
          showModal={showAssetModal}
          setShowModal={setShowAssetModal}
          assetType={categoryKey}
          setNewAsset={setNewAsset}
        />
      )}
      <SelectableModal
        title={modalTitle}
        open={open}
        onClose={closeModal}
        categoryKey={categoryKey}
        query={SUBSCRIPTION_BADGES}
        variablesSelector={(offset, limit, search) => ({
          filter: {
            limit,
            skip: offset,
            search,
            isActive: true
          },
          sort: {
            sortBy: 'ASC',
            sortOn: 'createdAt'
          }
        })}
        dataSelector={(data) => data?.badgesAdmin?.badges || []}
        keys={{
          data: 'badges',
          records: 'badges',
          count: 'count'
        }}
        assetModal={false}
        isAssetEditAllowed={isAssetEditAllowed}
        newAsset={newAsset}
        setShowAssetModal={setShowAssetModal}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value.map((val) => val.id).includes(item.id)
            : value?.id === item?.id;

          return (
            <Card
              className={`selectable-modal-card cs-card ${
                isSelected ? 'active' : ''
              }`}
              cover={
                <>
                  {isSelected && (
                    <span className="checked-icon">
                      <CheckOutlined />
                    </span>
                  )}
                  <Image
                    preview={false}
                    className="label-poster"
                    src={item?.image?.url}
                    alt=""
                  />
                </>
              }
              onClick={() => handleCardSelect(item)}
            >
              <Card.Meta
                title={
                  <span title={item.title} className="label-title">
                    {item.title}
                  </span>
                }
                description={
                  <Typography.Paragraph
                    className="asset-description"
                    ellipsis={{ rows: 2 }}
                  >
                    {moment(item.createdAt).format('ll')}
                  </Typography.Paragraph>
                }
              />
            </Card>
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  {...(allowClear && {
                    onRemove: () => handleRemove(item)
                  })}
                  IsTableView={IsTableView}
                  key={item.id}
                  bgImg={getImageUrl(item.image.url, {
                    height: 200,
                    width: 200
                  })}
                >
                  <Preview.Title color={colorText}>{item.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                IsTableView={IsTableView}
                {...(allowClear && {
                  onRemove: handleRemove
                })}
                bgImg={getImageUrl(value?.url || value?.image?.url, {
                  height: 200,
                  width: 200
                })}
              >
                {value.title && (
                  <Preview.Title color={colorText}>{value.title}</Preview.Title>
                )}
              </Preview>
            )}
          </>
        )}
        <Button
          id={id}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || (!isAssetViewAllowed && !isAssetEditAllowed)}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.id ? 'Change' : 'Select'} {btnText}
        </Button>
      </Space>
    </>
  );
};

export default SelectBadge;
