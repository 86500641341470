import { Carousel } from 'antd';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import CollectionCard from './CollectionCard';

function CollectionPillCarousel({
  sectionHeading,
  headerLink,
  headerDescription,
  collectionMd,
  collectionLg,
  responsive,
  data = []
}) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
    draggable: true,
    loop: true,
    arrows: !responsive,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToScroll: 1,
          arrows: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <section
      className={`collection-carousel ${responsive ? 'responsive-view' : ' '}`}
    >
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
      </div>
      <div className="one-side-carousel shadow-arrow">
        <Carousel {...settings}>
          {data?.map((item) => {
            return (
              <div key={item?.id}>
                <CollectionCard
                  href={item?.href}
                  collectionMd={collectionMd}
                  collectionLg={collectionLg}
                  title={item.title}
                  coverImg={item.coverImg}
                  blurHash={item.blurHash}
                  videoCount={item.videoCount}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
}
export default CollectionPillCarousel;
