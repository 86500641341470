import { CheckOutlined } from '@ant-design/icons';
import { NetworkStatus, useQuery } from '@apollo/client';
import {
  DatePicker as AntdDatePicker,
  Select as AntdSelect,
  Button,
  Card,
  Empty,
  Space,
  Spin
} from 'antd';
import dayjs from 'dayjs';
import { debounce, get, isFunction, isObject } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import videoPlaceholder from '../../../assets/images/imagePlaceholder.png';
import { EDITOR_MODULES, PROVIDER_TYPES } from '../../../common/constants';
import Image from '../../../components/Image';
import SelectableModal from '../../../components/SelectableModal';
import { GET_VIDEOS_BY_WORKSPACE_PLATFORM } from '../../assets/graphql/Queries';
import { GET_PLATFORMS } from '../../platforms/graphql/Queries';
import Preview from './Preview';

const { Option } = AntdSelect;

// Constants

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return { open: isOpen, openModal, closeModal };
};

const LIMIT = 30;
// Components
export const Select = forwardRef(
  (
    {
      query,
      dataSelector,
      variablesSelector,
      multiple = false,
      searchable = true,
      limit = LIMIT,
      keys,
      excludeOptions = [],
      includeOptions = [],
      queryOptions = {},
      notFoundContent = 'No data!',
      renderOption,
      fetchPolicy = 'network-only',
      ...rest
    },
    ref
  ) => {
    const [search, setSearch] = useState('');

    const { networkStatus, data, fetchMore, refetch } = useQuery(query, {
      ...(variablesSelector &&
        isFunction(variablesSelector) && {
          variables: variablesSelector({ skip: 0, limit, search })
        }),
      notifyOnNetworkStatusChange: true,
      fetchPolicy,
      ...queryOptions
    });

    useImperativeHandle(ref, () => ({ refetch }), [refetch]);

    const options = useMemo(
      () =>
        data
          ? [
              ...includeOptions,
              ...(dataSelector(data)?.filter?.(
                ({ value }) => !excludeOptions?.includes(value)
              ) ?? [])
            ]
          : [],
      [data, includeOptions, excludeOptions]
    );
    const count = useMemo(
      () =>
        data && isObject(keys)
          ? get(data, [keys?.data, keys?.count], 0) +
            includeOptions?.length -
            excludeOptions?.length
          : 0,
      [data, includeOptions, excludeOptions]
    );

    const hasMoreData = count > options.length;
    const loading =
      networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables;
    const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

    const handleClose = (isOpen) => {
      if (!isOpen) setSearch('');
    };

    const fetchMoreRecords = useCallback(
      ({ skip, search: searchValue } = {}) => {
        fetchMore({
          variables: variablesSelector({ skip, limit, search: searchValue }),
          updateQuery: (prev, { fetchMoreResult }) => {
            if (
              fetchMoreResult &&
              keys &&
              isObject(keys) &&
              'data' in keys &&
              'records' in keys &&
              'count' in keys
            ) {
              return {
                ...prev,
                [keys.data]: {
                  ...get(prev, keys.data),
                  [keys.records]: [
                    ...get(prev, [keys.data, keys.records]),
                    ...get(fetchMoreResult, [keys.data, keys.records])
                  ],
                  [keys.count]: get(fetchMoreResult, [keys.data, keys.count])
                }
              };
            }
            return { ...prev };
          }
        });
      },
      [fetchMore]
    );

    const handleScroll = (e) => {
      e.stopPropagation();
      const { target } = e;
      if (!isFetchingMore && hasMoreData && !loading) {
        if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
          fetchMoreRecords({ skip: options.length, search });
        }
      }
    };

    return (
      <>
        <AntdSelect
          labelInValue
          filterOption={false}
          loading={loading || isFetchingMore}
          notFoundContent={
            loading ? (
              <Spin size="small" />
            ) : (
              <Empty className="text-white-70" description={notFoundContent} />
            )
          }
          onDropdownVisibleChange={handleClose}
          {...(multiple && {
            mode: 'multiple'
          })}
          {...(searchable && {
            showSearch: true,
            onSearch: debounce(setSearch, 500)
          })}
          {...(isFunction(variablesSelector) &&
            isObject(keys) && {
              onPopupScroll: handleScroll
            })}
          {...(!isFunction(renderOption) && {
            options
          })}
          {...rest}
        >
          {isFunction(renderOption) &&
            options.map((option) => (
              <Option key={option?.value} value={option?.value}>
                {renderOption(option)}
              </Option>
            ))}
        </AntdSelect>
      </>
    );
  }
);

export const DatePicker = ({ value, onChange, ...rest }) => {
  return (
    <AntdDatePicker
      style={{
        width: '100%'
      }}
      disabledDate={(day) => dayjs().subtract(1, 'day').isAfter(day)}
      value={value ? dayjs(value) : null}
      onChange={(_, date) =>
        onChange(date ? dayjs(date).format('YYYY-MM-DD') : '')
      }
      {...rest}
    />
  );
};

export const Editor = ({
  value,
  onChange,
  modules = EDITOR_MODULES,
  readOnly = false
}) => {
  return (
    <ReactQuill
      // using readOnly as disabled not supported
      readOnly={readOnly}
      value={value}
      onChange={(val) => {
        if (val === '<p><br></p>') onChange('');
        else onChange(val);
      }}
      modules={modules}
    />
  );
};

export const SelectPlatForm = ({
  value,
  onChange,
  type,
  fullWidth = false,
  setPlatform,
  ...rest
}) => {
  const variableSelector = useCallback(
    ({ skip, limit, search }) => ({
      filter: {
        skip,
        limit,
        search,
        type
      }
    }),
    [type]
  );

  return (
    <Select
      popupMatchSelectWidth={false}
      placement="bottomRight"
      variablesSelector={variableSelector}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      placeholder="Select platform"
      className={fullWidth ? 'w-full' : 'video-platform-select-filter'}
      query={GET_PLATFORMS}
      dataSelector={(data) =>
        data?.workspacePlatforms?.workspacePlatforms?.map(
          ({ id, provider, name }) => ({
            label: `${provider?.name ?? ''} - ${name}`,
            value: id,
            provider
          })
        ) ?? []
      }
      keys={{
        data: 'workspacePlatforms',
        records: 'workspacePlatforms',
        count: 'count'
      }}
      queryOptions={{
        ...(setPlatform && {
          onCompleted: ({ workspacePlatforms }) => {
            const firstPlatform = workspacePlatforms?.workspacePlatforms?.[0];
            if (firstPlatform)
              setPlatform({
                label: `${firstPlatform?.provider?.name ?? ''} - ${
                  firstPlatform?.name
                }`,
                value: firstPlatform?.id,
                provider: firstPlatform?.provider
              });
          }
        })
      }}
      {...rest}
    />
  );
};

const platformVideosVariableSelector = (offset, limit, search, filters) => ({
  filter: {
    skip: offset,
    limit,
    search
  },
  where: {
    id: filters.platform,
    type: PROVIDER_TYPES.VIDEO
  }
});

export const SelectProviderVideo = ({
  value,
  onChange,
  multiple = false,
  id: fieldId
}) => {
  const { open, openModal, closeModal } = useModal();
  const [platform, setPlatform] = useState(null);

  const handleCardSelect = (item, platformProvider) => {
    const { id, title, description, imageThumbnailUrl } = item;
    if (multiple) {
      const isPresent = value.find((items) => items.id === id);
      if (isPresent) {
        onChange(value.filter((items) => items.id !== id));
      } else {
        onChange([
          ...value,
          {
            id,
            title,
            description,
            imageURL: imageThumbnailUrl ?? '',
            platformId: platformProvider?.value
          }
        ]);
      }
      return;
    }
    onChange({
      id,
      title,
      description,
      imageURL: imageThumbnailUrl ?? '',
      platformId: platformProvider?.value
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value.filter((items) => items.id !== item.id));
    }
  };

  const handlePlatformChange = (newValue) => {
    setPlatform(newValue);
  };

  const filters = useMemo(
    () => ({ platform: platform ? platform?.value : '' }),
    [platform]
  );

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Videos' : 'Select Video'}
        open={open}
        onClose={closeModal}
        query={GET_VIDEOS_BY_WORKSPACE_PLATFORM}
        variablesSelector={platformVideosVariableSelector}
        dataSelector={(data) =>
          data?.getVideosByWorkspacePlatform?.platformVideos || []
        }
        queryOptions={{
          skip: !platform
        }}
        keys={{
          data: 'getVideosByWorkspacePlatform',
          records: 'platformVideos',
          count: 'count'
        }}
        filters={filters}
        renderFilters={() => (
          <SelectPlatForm
            type={PROVIDER_TYPES.VIDEO}
            onChange={handlePlatformChange}
            value={platform}
            setPlatform={setPlatform}
          />
        )}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value.map((val) => val.id).includes(item.id)
            : value?.id === item?.id;
          return (
            <Card
              className="selectable-modal-card cs-card"
              cover={
                <>
                  {isSelected && (
                    <span className="checked-icon">
                      <CheckOutlined />
                    </span>
                  )}
                  <Image
                    className="label-poster"
                    src={
                      item?.videoThumbnailUrl ??
                      item?.imageThumbnailUrl ??
                      videoPlaceholder
                    }
                    alt=""
                  />
                </>
              }
              onClick={() => handleCardSelect(item, platform)}
            >
              <Card.Meta
                title={<span className="label-title">{`${item.title}`}</span>}
              />
            </Card>
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value.length > 0 &&
              value.map((item) => (
                <Preview
                  key={item.id}
                  bgImg={item?.imageURL ?? videoPlaceholder}
                  onRemove={() => handleRemove(item)}
                >
                  <Preview.Title bgColor={item.color}>
                    {item.title}
                  </Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                bgImg={value?.imageURL ?? videoPlaceholder}
                onRemove={handleRemove}
              >
                <Preview.Title>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button id={fieldId} htmlType="button" onClick={openModal}>
          {value?.id ? 'Change' : 'Select'} {multiple ? 'Videos' : 'Video'}
        </Button>
      </Space>
    </>
  );
};
