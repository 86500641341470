import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space
} from 'antd';
import dayjs from 'dayjs';
import { capitalize, isObject, map } from 'lodash';
import { CalendarBlank } from 'phosphor-react';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_SCHEDULED,
  STATUS_TYPES,
  UNPUBLISHED_STATUS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION,
  defaultDateFormat,
  defaultDateFormatWithTime
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import AllowedPlansTable from '../../components/AllowedPlansTable';
import HtmlEditor from '../../components/HtmlEditor';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import SelectAsset, { initialAsset } from '../assets/components/SelectAsset';
import { SlugInput } from '../labels/topics/components/FormInputs';
import { Permissions } from '../pages/component/pageModules/moduleForms/FormInputs';
import { Select } from '../videos/components/FormInputs';
import { GET_TAGS, GET_TOPICS } from '../videos/graphql/Queries';
import { CREATE_PODCAST, UPDATE_PODCAST } from './graphql/Mutations';
import { GET_PODCAST } from './graphql/Queries';

const initialValues = {
  title: '',
  description: '',
  slug: '/',
  code: null,
  tags: [],
  topics: [],
  status: STATUS_TYPES.DRAFT,
  contentRating: '',
  image: {
    ...initialAsset
  },
  imageThumbnail: {
    ...initialAsset
  },
  audio: {
    ...initialAsset
  },
  video: {
    ...initialAsset
  },
  previewAudio: {
    ...initialAsset
  },
  previewVideo: {
    ...initialAsset
  },
  metaHeader: '',
  metaFooter: '',
  descriptionHtml: '',
  permissions: []
};
const AddEditPodcast = ({ history, match: { params } }) => {
  const [form] = Form.useForm();
  const { podcastId } = params;
  const isEdit = !!podcastId;

  const [fetchPodcastDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_PODCAST,
    {
      fetchPolicy: 'network-only'
    }
  );

  const handleChangeDate = (event) => {
    if (event) {
      form.setFieldsValue({
        status: capitalize(STATUS_SCHEDULED)
      });
    } else {
      form.setFieldsValue({
        status: STATUS_TYPES.DRAFT
      });
    }
  };

  useEffect(() => {
    if (isEdit && !!podcastId) {
      fetchPodcastDetails({
        variables: {
          where: {
            id: podcastId
          }
        }
      }).then((res) => {
        const podcast = res?.data?.podcastAdmin;
        if (podcast) {
          const slug = podcast?.slug?.startsWith('/')
            ? podcast?.slug
            : `/${podcast?.slug}`;
          form.setFieldsValue({
            title: podcast?.title ?? '',
            description: podcast?.description ?? '',
            slug,
            permissions:
              podcast?.permissions?.map((value) => ({
                label: value,
                value
              })) ?? [],
            tags:
              podcast?.tags?.map((tag) => ({
                label: tag?.name,
                value: tag?.id
              })) ?? [],
            topics:
              podcast?.topics?.length > 0
                ? map(podcast?.topics, (item) => {
                    return {
                      value: item?.id,
                      label: item?.name
                    };
                  })
                : [],

            status:
              podcast?.status === STATUS_SCHEDULED
                ? capitalize(STATUS_SCHEDULED)
                : podcast?.status ?? STATUS_TYPES.DRAFT,
            contentRating: podcast?.contentRating ?? '',
            displayDate: podcast?.displayDate
              ? dayjs(podcast?.displayDate)
              : null,
            autoPublishAt: podcast?.autoPublishAt
              ? dayjs(podcast?.autoPublishAt)
              : null,
            image: {
              id: podcast?.image?.id ?? '',
              url: podcast?.image?.url ?? ''
            },
            imageThumbnail: {
              id: podcast?.imageThumbnail?.id ?? '',
              url: podcast?.imageThumbnail?.url ?? ''
            },
            audio: podcast?.audio
              ? {
                  id: podcast?.audio?.id,
                  url: podcast?.audio?.serviceImageThumbnail,
                  title: podcast?.audio?.title
                }
              : {},
            video: podcast?.video
              ? {
                  id: podcast?.video?.id,
                  url: podcast?.video?.serviceImageThumbnail,
                  title: podcast?.video?.title
                }
              : {},
            previewAudio: podcast?.previewAudio
              ? {
                  id: podcast?.previewAudio?.id,
                  url: podcast?.previewAudio?.serviceImageThumbnail,
                  title: podcast?.previewAudio?.title
                }
              : {},
            previewVideo: podcast?.previewVideo
              ? {
                  id: podcast?.previewVideo?.id,
                  url: podcast?.previewVideo?.serviceImageThumbnail,
                  title: podcast?.previewVideo?.title
                }
              : {},
            descriptionHtml: podcast?.descriptionHtml ?? '',
            metaHeader: podcast?.metaHeader ?? '',
            metaFooter: podcast?.metaFooter ?? '',
            code: podcast?.code ?? null,
            allowedPlans:
              podcast?.allowedPlans?.map((item) => ({
                ...item,
                key: { label: item?.key, value: item?.key },
                expiredAt: item?.expiredAt ? dayjs(item?.expiredAt) : null
              })) ?? []
          });
        }
      });
    }
  }, [isEdit, podcastId, form, fetchPodcastDetails]);

  const [addUpdatePodcast, { loading }] = useMutation(
    isEdit ? UPDATE_PODCAST : CREATE_PODCAST
  );

  const handleSubmit = (data) => {
    const payload = {
      title: data?.title || '',
      description: data?.description || '',
      autoPublishAt:
        data?.autoPublishAt && dayjs(data?.autoPublishAt).toISOString(),
      displayDate: data?.displayDate && dayjs(data?.displayDate).toISOString(),
      slug: data?.slug?.startsWith('/') ? data?.slug?.substring(1) : data?.slug,
      tags:
        data?.tags?.map((item, index) => {
          return {
            tagId: item?.value,
            order: index + 1
          };
        }) || [],
      topics:
        data?.topics?.length > 0
          ? map(data?.topics, (item, index) => {
              return {
                topicId: item?.value,
                order: index + 1
              };
            })
          : [],
      status:
        data?.status === capitalize(STATUS_SCHEDULED) ? null : data?.status,
      contentRating: data?.contentRating || '',
      imageId: data?.image?.id || null,
      imageThumbnailId: data?.imageThumbnail?.id || null,
      audio: {
        id: data?.audio?.id,
        previewId: data?.previewAudio?.id || null
      },
      video: {
        id: data?.video?.id || null,
        previewId: data?.previewVideo?.id || null
      },
      descriptionHtml: data?.descriptionHtml ?? '',
      metaHeader: data?.metaHeader || '',
      metaFooter: data?.metaFooter || '',
      permissions: data?.permissions?.map(({ value }) => value),
      allowedPlans: data?.allowedPlans?.map(({ key, expiredAt }) => ({
        key: isObject(key) ? key?.value : key,
        expiredAt: expiredAt ? dayjs(expiredAt) : null
      }))
    };

    addUpdatePodcast({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: podcastId
          }
        })
      }
    }).then(() => {
      history.push(ROUTES.PODCAST_MODULE);
    });
  };

  const handleCancel = () => {
    history.replace(ROUTES.PODCAST_MODULE);
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <div>
      <PageHeader menu={MODULES.CONTENT_UNITS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails}
          >
            <Row gutter={[16, 0]}>
              <Col md={24} lg={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter title!'),
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <Input
                    placeholder="Enter title"
                    onChange={handleTitleChange}
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
                <Form.Item
                  label="Slug"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter slug!'
                    },
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <SlugInput />
                </Form.Item>
                <Form.Item name="tags" label="Tags">
                  <Select
                    mode="multiple"
                    placeholder="Select tags"
                    query={GET_TAGS}
                    variablesSelector={(filter) => ({ filter })}
                    dataSelector={(data) =>
                      data?.tagsAdmin?.tags?.map(({ id, name }) => ({
                        label: name,
                        value: id
                      })) ?? []
                    }
                    keys={{
                      data: 'tagsAdmin',
                      records: 'tags',
                      count: 'count'
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="topics"
                  label="Topic"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (!value?.length) {
                          throw new Error('Please select topic!');
                        }
                      }
                    }
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select topic"
                    query={GET_TOPICS}
                    variablesSelector={(filter) => ({ filter })}
                    dataSelector={(data) =>
                      data?.topicsAdmin?.topics?.map(({ id, name }) => ({
                        label: name,
                        value: id
                      })) ?? 0
                    }
                    keys={{
                      data: 'topicsAdmin',
                      records: 'topics',
                      count: 'count'
                    }}
                  />
                </Form.Item>
                <Form.Item label="Status" name="status">
                  <AntdSelect
                    options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                      ({ name, value }) => ({
                        label: name,
                        value
                      })
                    )}
                    placeholder="Select status"
                  />
                </Form.Item>
                <Form.Item
                  name="contentRating"
                  label="Content Rating"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter content rating" />
                </Form.Item>
                <Form.Item
                  label="Audio"
                  name="audio"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (!value?.id?.length) {
                          throw new Error('Please select audio!');
                        }
                      }
                    }
                  ]}
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Audio"
                    btnText="Audio"
                    categoryKey={ASSET_CATEGORY.AUDIO}
                    dataSelector={({ id, serviceImageThumbnail, title }) => ({
                      id,
                      title,
                      url: serviceImageThumbnail
                    })}
                  />
                </Form.Item>
                <Form.Item label="Preview Audio" name="previewAudio">
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Preview Audio"
                    btnText="Preview Audio"
                    categoryKey={ASSET_CATEGORY.AUDIO}
                    dataSelector={({ id, serviceImageThumbnail, title }) => ({
                      id,
                      title,
                      url: serviceImageThumbnail
                    })}
                  />
                </Form.Item>
                <Form.Item label="Video" name="video">
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Video"
                    categoryKey={ASSET_CATEGORY.VIDEO}
                    btnText="Video"
                    dataSelector={({
                      id,
                      serviceImageThumbnail,
                      serviceVideoThumbnail
                    }) => ({
                      id,
                      url: serviceVideoThumbnail || serviceImageThumbnail
                    })}
                  />
                </Form.Item>
                <Form.Item label="Preview Video" name="previewVideo">
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Preview Video"
                    categoryKey={ASSET_CATEGORY.VIDEO}
                    btnText="Preview Video"
                    dataSelector={({
                      id,
                      serviceImageThumbnail,
                      serviceVideoThumbnail
                    }) => ({
                      id,
                      url: serviceVideoThumbnail || serviceImageThumbnail
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label="Image"
                  name="image"
                  extra="Recommended size  (2500 * 1242)"
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Image"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label="Image Thumbnail"
                  name="imageThumbnail"
                  extra="Recommended size  (590 * 330)"
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Image Thumbnail"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image Thumbnail"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item label="Display Date" name="displayDate">
                  <DatePicker
                    suffixIcon={<CalendarBlank size={20} />}
                    format={defaultDateFormat}
                  />
                </Form.Item>
                <Form.Item label="Auto Publish On" name="autoPublishAt">
                  <DatePicker
                    showTime
                    suffixIcon={<CalendarBlank size={20} />}
                    disabledDate={(day) => dayjs().isAfter(day, 'day')}
                    format={defaultDateFormatWithTime}
                    onChange={handleChangeDate}
                  />
                </Form.Item>
                <Form.Item
                  name="metaHeader"
                  label="Meta Header"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta header" />
                </Form.Item>

                <Form.Item
                  name="metaFooter"
                  label="Meta Footer"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta footer" />
                </Form.Item>

                <Permissions />
                <fieldset className="mb-12">
                  <legend className="role-legend">Allowed Plans</legend>
                  <AllowedPlansTable
                    loading={fetchingDetails}
                    form={form}
                    formKey="allowedPlans"
                  />
                </fieldset>
              </Col>
              <Col md={24} lg={12}>
                <Form.Item
                  className="full-html"
                  label="Html"
                  name="descriptionHtml"
                >
                  <HtmlEditor disabled={isViewOnly} className="full-html" />
                </Form.Item>
              </Col>
            </Row>
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditPodcast;
