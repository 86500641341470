import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PAGES = gql`
  query pagesAdmin($filter: PagesAdminFilter, $sort: PagesSort) {
    pagesAdmin(filter: $filter, sort: $sort) {
      count
      pages {
        id
        slug
        title
        description
        status
        type
        isDefault
        allowDelete
        createdBy {
          id
          email
        }
        pageGroup {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PAGE = gql`
  query pageAdmin($where: PageUniqueInput!) {
    pageAdmin(where: $where) {
      id
      slug
      title
      description
      status
      type
      isDefault
      metaHeader
      metaFooter
      metaImage
      pageGroup {
        name
        id
      }
      createdBy {
        id
        email
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_PAGE_MODULE_TYPES = gql`
  query pageModuleTypes {
    pageModuleTypes {
      category
      key
      name
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query permissions {
    permissions {
      count
      permissions {
        key
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_TOPIC = gql`
  query topicsAdmin($filter: TopicsFilter) {
    topicsAdmin(filter: $filter) {
      count
      topics {
        id
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_TAGS = gql`
  query tagsAdmin($filter: TagsFilter) {
    tagsAdmin(filter: $filter) {
      count
      tags {
        id
        name
      }
    }
  }
`;

export const GET_AUTOGENERATE_BY_COLLECTIONS = gql`
  query collectionsAdmin($filter: CollectionsFilter) {
    collectionsAdmin(filter: $filter) {
      count
      collections {
        id
        title
        description
      }
    }
  }
`;

const video = `
    video {
      id
      title
      videoThumbnail {
        url
      }
    }
`;
const videos = `
  videos {
    order
    id
    title
    videoThumbnail {
      url
    }
  }
`;

const getVideoConfig = (configKey, key, isMultiVideo = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiVideo ? videos : video}
    autoGenerate
    autoGenerateByType
    videoAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateByData {
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
    }
  }
`;

const VIDEO_CONTINUE_WATCHING_CAROUSEL_FRAGMENT = gql`
  fragment videoContinueWatchingCarouselData on VideoContinueWatchingCarouselData {
    settings {
      title
      description
      viewAll
      videoTimestamp
      videoDate
      videoTopic
      videoTag
      videoOrder
    }
  }
`;

const VIDEO_CAROUSEL_CONFIG_FRAGMENT = gql(
  getVideoConfig('carouselConfig', 'VideoCarouselModuleConfigData')
);
const VIDEO_CAROUSEL_FRAGMENT = gql`
  ${VIDEO_CAROUSEL_CONFIG_FRAGMENT}
  fragment videoCarouselData on VideoCarouselData {
    settings {
      title
      description
      viewAll
      videoTimestamp
      videoDate
      videoTopic
      videoTag
      videoOrder
      videoProgressBar
    }
    config {
      ...carouselConfig
      autoGenerateLimit
    }
  }
`;

const VIDEO_LIST_CONFIG_FRAGMENT = gql(
  getVideoConfig('listConfig', 'VideoListModuleConfigData')
);
const VIDEO_LIST_FRAGMENT = gql`
  ${VIDEO_LIST_CONFIG_FRAGMENT}
  fragment videoListFragment on VideoListData {
    settings {
      title
      description
      separator
      videoTimestamp
      videoDate
      videoTopic
      videoTag
      videoOrder
      videoProgressBar
      videoDescription
      viewAll
    }
    config {
      ...listConfig
      autoGenerateLimit
    }
  }
`;

const VIDEO_GRID_CONFIG_FRAGMENT = gql(
  getVideoConfig('gridConfig', 'VideoGridModuleConfigData')
);
const VIDEO_GRID_FRAGMENT = gql`
  ${VIDEO_GRID_CONFIG_FRAGMENT}
  fragment videoGridFragment on VideoGridData {
    settings {
      title
      description
      videoTimestamp
      videoDate
      videoTopic
      videoTag
      videoOrder
      videoProgressBar
      viewAll
      actionButton
    }
    config {
      ...gridConfig
      actionTitle
      autoGenerateLimit
    }
  }
`;
const FEATURED_VIDEO_CONFIG_FRAGMENT = gql(
  getVideoConfig('featureVideoConfig', 'FeaturedVideoModuleConfigData', false)
);
const FEATURED_VIDEO_FRAGMENT = gql`
  ${FEATURED_VIDEO_CONFIG_FRAGMENT}
  fragment featuredVideoFragment on FeaturedVideoData {
    settings {
      videoTitle
      videoDescription
      videoTimestamp
      videoDate
      videoTopic
      videoTag
      videoCTA
      collectionNames
    }
    config {
      ...featureVideoConfig
    }
  }
`;

const FEATURED_VIDEO_SLIDER_CONFIG_FRAGMENT = gql(
  getVideoConfig(
    'featureVideoSliderConfig',
    'FeaturedVideoSliderModuleConfigData'
  )
);
const FEATURED_VIDEO_SLIDER_FRAGMENT = gql`
  ${FEATURED_VIDEO_SLIDER_CONFIG_FRAGMENT}
  fragment featuredVideoSlicerFragment on FeaturedVideoSliderData {
    settings {
      videoTitle
      videoDescription
      videoTimestamp
      videoDate
      videoTopic
      videoTag
      carouselIndicator
      videoCTA
      collectionNames
    }
    config {
      ...featureVideoSliderConfig
      autoGenerateLimit
    }
  }
`;

const VIDEO_PLAYER_FRAGMENT = gql`
  fragment videoPlayerFragment on VideoPlayerData {
    settings {
      videoTitle
      videoDescription
      videoDate
      videoTopic
      videoTag
      videoOrder
    }
    config {
      video {
        id
        title
        videoThumbnail {
          url
        }
      }
    }
  }
`;

const podcast = `
  id
  title
  imageThumbnail {
    url
  }
`;

const podcasts = `
  podcasts {
    order
    ${podcast}
  }
`;

const getPodcastConfig = (configKey, key, isMultiPodcast = true) => `
  fragment ${configKey} on ${key} {
    ${isMultiPodcast ? podcasts : podcast}
    autoGenerate
    autoGenerateByType
    podcastAutoGenerateSortBy: autoGenerateSortBy
    autoGenerateLimit
    autoGenerateByData {
      ... on Topic {
        topicId: id
        topicName: name
      }
      ... on Tag {
        tagId: id
        tagName: name
      }
      ... on Collection {
        collectionId: id
        collectionTitle: title
      }
    }
  }
`;

const PODCAST_LIST_CONFIG_FRAGMENT = gql(
  getPodcastConfig('podcastListConfig', 'PodcastListModuleConfigData')
);
const PODCAST_LIST_FRAGMENT = gql`
  ${PODCAST_LIST_CONFIG_FRAGMENT}
  fragment podcastListFragment on PodcastListData {
    settings {
      title
      description
      separator
      podcastTimestamp
      podcastDate
      podcastOrder
      imageThumbnail
      podcastProgressBar
      podcastDescription
      viewAll
    }
    config {
      ...podcastListConfig
    }
  }
`;

const PODCAST_PLAYER_FRAGMENT = gql`
  fragment podcastPlayerFragment on PodcastPlayerData {
    settings {
      podcastAsk
      podcastShare
      podcastDate
      podcastDescription
      podcastOrder
      podcastTimestamp
    }
    config {
      podcast {
        id
        title
        imageThumbnail {
          url
        }
      }
    }
  }
`;

const collection = `
  collection {
    id
    title
    thumbnail {
      url
    }
  }
`;
const collections = `
  collections {
    order
    id
    title
    thumbnail {
      url
    }
  }
`;
const getCollectionConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    ${key !== 'CollectionPillCarouselModuleConfigData' ? 'aspectRatio' : ''}
    collectionAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? collections : collection}
  }
`;

const COLLECTION_CAROUSEL_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionCarouselConfigFragment',
    'CollectionCarouselModuleConfigData'
  )
);

const COLLECTION_CAROUSEL_FRAGMENT = gql`
  ${COLLECTION_CAROUSEL_CONFIG_FRAGMENT}
  fragment collectionCarouselFragment on CollectionCarouselData {
    settings {
      title
      description
      viewAll
      numberOfItems
    }
    config {
      ...collectionCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const COLLECTION_PILL_CAROUSEL_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionPillCarouselConfigFragment',
    'CollectionPillCarouselModuleConfigData'
  )
);

const COLLECTION_PILL_CAROUSEL_FRAGMENT = gql`
  ${COLLECTION_PILL_CAROUSEL_CONFIG_FRAGMENT}
  fragment collectionPillCarouselFragment on CollectionPillCarouselData {
    settings {
      title
      viewAll
    }
    config {
      ...collectionPillCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const COLLECTION_GRID_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionGridConfigFragment',
    'CollectionGridModuleConfigData'
  )
);

const COLLECTION_GRID_FRAGMENT = gql`
  ${COLLECTION_GRID_CONFIG_FRAGMENT}
  fragment collectionGridFragment on CollectionGridData {
    settings {
      title
      description
      numberOfItems
      viewAll
      collectionDescription
    }
    config {
      ...collectionGridConfigFragment
      autoGenerateLimit
    }
  }
`;

const COLLECTION_LIST_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'collectionListConfigFragment',
    'CollectionListModuleConfigData'
  )
);
const COLLECTION_LIST_FRAGMENT = gql`
  ${COLLECTION_LIST_CONFIG_FRAGMENT}
  fragment collectionListFragment on CollectionListData {
    settings {
      title
      description
      separator
      collectionDescription
      numberOfItems
      viewAll
    }
    config {
      ...collectionListConfigFragment
      autoGenerateLimit
    }
  }
`;

const FEATURED_COLLECTION_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'featuredCollectionConfigFragment',
    'FeaturedCollectionModuleConfigData',
    false
  )
);

const FEATURED_COLLECTION_FRAGMENT = gql`
  ${FEATURED_COLLECTION_CONFIG_FRAGMENT}
  fragment featuredCollectionFragment on FeaturedCollectionData {
    settings {
      title
      description
      numberOfItems
      collectionDescription
    }
    config {
      ...featuredCollectionConfigFragment
    }
  }
`;

const COLLECTION_DETAIL_FRAGMENT = gql`
  fragment CollectionDetailFragment on CollectionDetailData {
    settings {
      title
      description
      showImageThumbnail
      showProgressBar
    }
    config {
      collection {
        id
        title
        thumbnail {
          url
        }
      }
    }
  }
`;

const FEATURED_COLLECTION_SLIDER_CONFIG_FRAGMENT = gql(
  getCollectionConfig(
    'featuredCollectionSliderConfigFragment',
    'FeaturedCollectionSliderModuleConfigData'
  )
);
const FEATURED_COLLECTION_SLIDER_FRAGMENT = gql`
  ${FEATURED_COLLECTION_SLIDER_CONFIG_FRAGMENT}
  fragment featuredCollectionSliderFragment on FeaturedCollectionSliderData {
    settings {
      collectionTitle
      collectionDescription
      collectionImage
    }
    config {
      ...featuredCollectionSliderConfigFragment
      autoGenerateLimit
    }
  }
`;

const tag = `
  tag {
    id
    name
  }
`;
const tags = `
  tags {
    order
    id
    name
  }
`;
const getTagConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    tagAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? tags : tag}
  }
`;

const TAG_CAROUSEL_CONFIG_FRAGMENT = gql(
  getTagConfig('tagCarouselConfigFragment', 'TagCarouselModuleConfigData')
);
const TAG_CAROUSEL_FRAGMENT = gql`
  ${TAG_CAROUSEL_CONFIG_FRAGMENT}
  fragment tagCarouselFragment on TagCarouselData {
    settings {
      title
      description
      viewAll
    }
    config {
      ...tagCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const TAG_CLOUD_CONFIG_FRAGMENT = gql(
  getTagConfig('tagCloudConfigFragment', 'TagCloudModuleConfigData')
);
const TAG_CLOUD_FRAGMENT = gql`
  ${TAG_CLOUD_CONFIG_FRAGMENT}
  fragment tagCloudFragment on TagCloudData {
    settings {
      title
      description
      viewAll
    }
    config {
      ...tagCloudConfigFragment
      autoGenerateLimit
    }
  }
`;

const FEATURED_TAG_CONFIG_FRAGMENT = gql(
  getTagConfig(
    'featuredTagConfigFragment',
    'FeaturedTagModuleConfigData',
    false
  )
);
const FEATURED_TAG_FRAGMENT = gql`
  ${FEATURED_TAG_CONFIG_FRAGMENT}
  fragment featuredTagFragment on FeaturedTagData {
    settings {
      tagTitle
    }
    config {
      ...featuredTagConfigFragment
    }
  }
`;

const topic = `
  topic {
    id
    name
  }
`;
const topics = `
  topics {
    order
    id
    name
  }
`;
const getTopicsConfig = (configKey, key, isMultiple = true) => `
 fragment ${configKey} on ${key} {
    autoGenerate
    topicAutoGenerateSortBy: autoGenerateSortBy
    ${isMultiple ? topics : topic}
  }
`;

const TOPIC_CAROUSEL_CONFIG_FRAGMENT = gql(
  getTopicsConfig(
    'topicCarouselConfigFragment',
    'TopicCarouselModuleConfigData'
  )
);
const TOPIC_CAROUSEL_FRAGMENT = gql`
  ${TOPIC_CAROUSEL_CONFIG_FRAGMENT}
  fragment topicCarouselFragment on TopicCarouselData {
    settings {
      description
      viewAll
      topicTitle
    }
    config {
      ...topicCarouselConfigFragment
      autoGenerateLimit
    }
  }
`;

const TOPIC_LIST_CONFIG_FRAGMENT = gql(
  getTopicsConfig('topicListConfigFragment', 'TopicListModuleConfigData')
);
const TOPIC_LIST_FRAGMENT = gql`
  ${TOPIC_LIST_CONFIG_FRAGMENT}
  fragment topicListFragment on TopicListData {
    settings {
      topicTitle
      viewAll
    }
    config {
      ...topicListConfigFragment
      autoGenerateLimit
    }
  }
`;

const TOPIC_GRID_CONFIG_FRAGMENT = gql(
  getTopicsConfig('topicGridConfigFragment', 'TopicGridModuleConfigData')
);
const TOPIC_GRID_FRAGMENT = gql`
  ${TOPIC_GRID_CONFIG_FRAGMENT}
  fragment topicGridFragment on TopicGridData {
    settings {
      description
      topicTitle
      viewAll
    }
    config {
      ...topicGridConfigFragment
      autoGenerateLimit
    }
  }
`;

const FEATURED_TOPIC_CONFIG_FRAGMENT = gql(
  getTopicsConfig(
    'featuredTopicConfigFragment',
    'FeaturedTopicModuleConfigData',
    false
  )
);
const FEATURED_TOPIC_FRAGMENT = gql`
  ${FEATURED_TOPIC_CONFIG_FRAGMENT}
  fragment featuredTopicFragment on FeaturedTopicData {
    settings {
      topicTitle
    }
    config {
      ...featuredTopicConfigFragment
    }
  }
`;

const CTA_BLOCK_FRAGMENT = gql`
  fragment ctaBlockFragment on CTABlockData {
    settings {
      image
      description
      secondaryAction
      eventDetails
    }
    config {
      asset {
        id
        url
      }
      theme
      eventTime
      eventDate
      eventLocation
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const CTA_BANNER_WITH_TEXT_FRAGMENT = gql`
  fragment ctaBannerWithTextFragment on CTABannerWithTextData {
    settings {
      title
      image
      description
    }
    config {
      asset {
        id
        url
      }
      theme
      primaryAction {
        title
        type
        url
        internalPageType
      }
    }
  }
`;

const HERO_FRAGMENT = gql`
  fragment heroFragment on HeroData {
    settings {
      description
      primaryAction
      secondaryAction
      textFields
    }
    config {
      asset {
        id
        url
        categoryKey
        serviceImageThumbnail
        serviceVideoThumbnail
      }
      eventTime
      eventDate
      eventLocation
      alignment
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const INFORMATION_FRAGMENT = gql`
  fragment informationFragment on InformationData {
    settings {
      description
      primaryAction
      secondaryAction
    }
    config {
      asset {
        id
        url
      }
      theme
      imageOrientation
      imageType
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          id
          url
        }
      }
    }
  }
`;

const CTA_BANNER_FRAGMENT = gql`
  fragment ctaBannerFragment on CTABannerData {
    config {
      foregroundImage {
        small {
          id
          url
        }
        medium {
          id
          url
        }
        large {
          id
          url
        }
      }
      backgroundImage {
        small {
          id
          url
        }
        medium {
          id
          url
        }
        large {
          id
          url
        }
      }
      primaryAction {
        internalPageType
        type
        url
      }
      assetHeight {
        desktop
        mobile
        tablet
      }
    }
    settings {
      primaryAction
    }
  }
`;

const FAQS_FRAGMENT = gql`
  fragment faqsFragment on FAQData {
    settings {
      title
      description
    }
    config {
      questions {
        question
        answer
      }
    }
  }
`;
const SPACER_FRAGMENT = gql`
  fragment spaceFragment on SpacerData {
    config {
      desktop
      mobile
      tablet
      tv
    }
  }
`;

const HTML_TEXT_BLOCK_FRAGMENT = gql`
  fragment htmlTextBlockFragment on HTMLTextBlockData {
    settings {
      title
      description
    }
    config {
      content
    }
  }
`;
const SUBSCRIPTION_HEADER_FRAGMENT = gql`
  fragment subscriptionHeaderFragment on SubscriptionHeaderData {
    settings {
      action
      title
    }
  }
`;
const SUBSCRIPTION_BLOCK_FRAGMENT = gql`
  fragment subscriptionBlockFragment on SubscriptionBlockData {
    settings {
      action
      description
      image
      previewTimestamp
      title
    }
    config {
      image {
        id
        url
      }
    }
  }
`;

const SUBSCRIPTION_LIST_FRAGMENT = gql`
  fragment subscriptionListFragment on SubscriptionListData {
    settings {
      title
      description
    }
    config {
      key
      subscriptionPlans {
        id
        html
        images {
          id
          url
        }
        key
      }
    }
  }
`;

const ARTICLE_LIST_FRAGMENT = gql`
  fragment articleListFragment on ArticleListData {
    settings {
      title
      description
    }
    config {
      actionName
      actionType
    }
  }
`;

const CTA_BUTTON_LIST_FRAGMENT = gql`
  fragment CTAButtonListDataFragment on CTAButtonListData {
    config {
      items {
        asset {
          id
          url
          title
        }
        buttonType
        internalPageType
        title
        type
        url
      }
    }
    settings {
      bottomSeparator
    }
  }
`;

const FORM_DISPLAY_FRAGMENT = gql`
  fragment FormDisplayDataFragment on FormDisplayData {
    settings {
      formTitle
      formDescription
    }
    config {
      autoFillForm
      form {
        id
        title
        ctas {
          label
          type
          action
          actionUrl
        }
        fields {
          key
          label
          type
          required
          order
          options {
            key
            label
            order
          }
        }
      }
    }
  }
`;

const FORM_CAROUSEL_FRAGMENT = gql`
  fragment FormCarouselDataFragment on FormCarouselData {
    settings {
      title
      description
      formTitle
      formShare
    }
    config {
      autoGenerate
      formAutoGenerateByType: autoGenerateByType
      autoGenerateSortBy
      autoGenerateByData {
        ... on Collection {
          collectionId: id
          collectionTitle: title
        }
      }
      sortBy
      type
      autoGenerateLimit
      formLimit
      forms {
        id
        title
        slug
        description
        type
        html
        ctas {
          label
          type
          action
          actionUrl
        }
        permissions
        isPremium
      }
    }
  }
`;

export const PAGE_MODULE_DETAILS_FRAGMENT = gql`
  ${VIDEO_CONTINUE_WATCHING_CAROUSEL_FRAGMENT}
  ${VIDEO_CAROUSEL_FRAGMENT}
  ${VIDEO_LIST_FRAGMENT}
  ${VIDEO_GRID_FRAGMENT}
  ${FEATURED_VIDEO_FRAGMENT}
  ${FEATURED_VIDEO_SLIDER_FRAGMENT}
  ${VIDEO_PLAYER_FRAGMENT}
  ${PODCAST_LIST_FRAGMENT}
  ${PODCAST_PLAYER_FRAGMENT}
  ${COLLECTION_CAROUSEL_FRAGMENT}
  ${COLLECTION_GRID_FRAGMENT}
  ${COLLECTION_PILL_CAROUSEL_FRAGMENT}
  ${COLLECTION_LIST_FRAGMENT}
  ${COLLECTION_DETAIL_FRAGMENT}
  ${FEATURED_COLLECTION_FRAGMENT}
  ${FEATURED_COLLECTION_SLIDER_FRAGMENT}
  ${TAG_CAROUSEL_FRAGMENT}
  ${TAG_CLOUD_FRAGMENT}
  ${FEATURED_TAG_FRAGMENT}
  ${TOPIC_CAROUSEL_FRAGMENT}
  ${TOPIC_GRID_FRAGMENT}
  ${TOPIC_LIST_FRAGMENT}
  ${FEATURED_TOPIC_FRAGMENT}
  ${CTA_BLOCK_FRAGMENT}
  ${CTA_BANNER_WITH_TEXT_FRAGMENT}
  ${HERO_FRAGMENT}
  ${CTA_BANNER_FRAGMENT}
  ${INFORMATION_FRAGMENT}
  ${FAQS_FRAGMENT}
  ${SPACER_FRAGMENT}
  ${HTML_TEXT_BLOCK_FRAGMENT}
  ${SUBSCRIPTION_HEADER_FRAGMENT}
  ${SUBSCRIPTION_BLOCK_FRAGMENT}
  ${SUBSCRIPTION_LIST_FRAGMENT}
  ${ARTICLE_LIST_FRAGMENT}
  ${CTA_BUTTON_LIST_FRAGMENT}
  ${FORM_DISPLAY_FRAGMENT}
  ${FORM_CAROUSEL_FRAGMENT}
  fragment pageModuleDetails on PageModule {
    id
    title
    description
    status
    permissions
    type
    isDefaultModule
    moduleData {
      ... on VideoContinueWatchingCarouselData {
        ...videoContinueWatchingCarouselData
      }
      ... on VideoCarouselData {
        ...videoCarouselData
      }
      ... on VideoListData {
        ...videoListFragment
      }
      ... on VideoGridData {
        ...videoGridFragment
      }
      ... on FeaturedVideoData {
        ...featuredVideoFragment
      }
      ... on FeaturedVideoSliderData {
        ...featuredVideoSlicerFragment
      }
      ... on VideoPlayerData {
        ...videoPlayerFragment
      }
      ... on PodcastListData {
        ...podcastListFragment
      }
      ... on PodcastPlayerData {
        ...podcastPlayerFragment
      }
      ... on CollectionCarouselData {
        ...collectionCarouselFragment
      }
      ... on CollectionListData {
        ...collectionListFragment
      }
      ... on CollectionPillCarouselData {
        ...collectionPillCarouselFragment
      }
      ... on CollectionGridData {
        ...collectionGridFragment
      }
      ... on FeaturedCollectionData {
        ...featuredCollectionFragment
      }
      ... on FeaturedCollectionSliderData {
        ...featuredCollectionSliderFragment
      }
      ... on CollectionDetailData {
        ...CollectionDetailFragment
      }
      ... on TagCarouselData {
        ...tagCarouselFragment
      }
      ... on TagCloudData {
        ...tagCloudFragment
      }
      ... on FeaturedTagData {
        ...featuredTagFragment
      }
      ... on TopicCarouselData {
        ...topicCarouselFragment
      }
      ... on TopicListData {
        ...topicListFragment
      }
      ... on TopicGridData {
        ...topicGridFragment
      }
      ... on FeaturedTopicData {
        ...featuredTopicFragment
      }
      ... on CTABlockData {
        ...ctaBlockFragment
      }
      ... on CTABannerWithTextData {
        ...ctaBannerWithTextFragment
      }
      ... on HeroData {
        ...heroFragment
      }
      ... on CTABannerData {
        ...ctaBannerFragment
      }
      ... on InformationData {
        ...informationFragment
      }
      ... on FAQData {
        ...faqsFragment
      }
      ... on SpacerData {
        ...spaceFragment
      }
      ... on HTMLTextBlockData {
        ...htmlTextBlockFragment
      }
      ... on SubscriptionHeaderData {
        ...subscriptionHeaderFragment
      }
      ... on SubscriptionBlockData {
        ...subscriptionBlockFragment
      }
      ... on SubscriptionListData {
        ...subscriptionListFragment
      }
      ... on ArticleListData {
        ...articleListFragment
      }
      ... on CTAButtonListData {
        ...CTAButtonListDataFragment
      }
      ... on FormDisplayData {
        ...FormDisplayDataFragment
      }
      ... on FormCarouselData {
        ...FormCarouselDataFragment
      }
    }
  }
`;

export const GET_PAGE_DETAILS = gql`
  ${PAGE_MODULE_DETAILS_FRAGMENT}
  query pageAdmin($id: ID!) {
    pageAdmin(where: { id: $id }) {
      slug
      title
      description
      metaHeader
      metaFooter
      metaImage
      permissions
      allowDelete
      isDefault
      type
      pageGroup {
        name
        id
      }
      apps {
        id
        name
      }
      modules {
        ...pageModuleDetails
      }
    }
  }
`;

export const GET_PAGE_DETAILS_FOR_PREVIEW = gql`
  fragment AssetFragment on Asset {
    categoryKey
    url
    blurhash
    serviceVideoThumbnail
    serviceImageThumbnail
  }

  fragment TopicFragment on Topic {
    id
    key
    name
    primaryColor
  }

  fragment TagFragment on Tag {
    id
    name
  }

  fragment VideoFragment on Video {
    id
    title
    description
    topics {
      id
      name
      primaryColor
    }
    collections {
      id
      slug
      title
    }
    tags {
      id
      name
    }
    createdAt
    video: videoAsset {
      id
      url
      information {
        durationInSeconds
      }
    }
    videoThumbnail {
      url
      blurhash
    }
  }

  fragment PodcastFragment on Podcast {
    id
    code
    contentRating
    createdAt
    description
    image {
      url
      blurhash
    }
    imageThumbnail {
      url
      blurhash
    }
    metaHeader
    metaFooter
    permissions
    slug
    tags {
      name
      id
    }
    title
    topics {
      key
      name
      id
      primaryColor
    }
    audio {
      id
      url
      serviceImageThumbnail
      information {
        durationInSeconds
      }
    }
  }

  fragment CollectionFragment on Collection {
    id
    title
    itemsCount
    thumbnail {
      url
      blurhash
    }
  }

  fragment VideoContinueWatchingCarouselFragment on VideoContinueWatchingCarouselData {
    settings {
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoCarouselFragment on VideoCarouselData {
    settings {
      title
      viewAll
      videoProgressBar
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoListFragment on VideoListData {
    settings {
      videoProgressBar
      separator
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment VideoGridFragment on VideoGridData {
    settings {
      videoProgressBar
      viewAll
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment FeaturedVideoSliderFragment on FeaturedVideoSliderData {
    settings {
      carouselIndicator
      videoCTA
    }
    config {
      videos {
        ...VideoFragment
        order
      }
    }
  }

  fragment FeaturedVideoFragment on FeaturedVideoData {
    settings {
      videoCTA
    }
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment VideoPlayerFragment on VideoPlayerData {
    config {
      video {
        ...VideoFragment
      }
    }
  }

  fragment PodcastListFragment on PodcastListData {
    settings {
      podcastProgressBar
      separator
      viewAll
    }
    config {
      podcasts {
        ...PodcastFragment
        order
      }
    }
  }

  fragment PodcastPlayerFragment on PodcastPlayerData {
    config {
      podcast {
        ...PodcastFragment
      }
    }
  }

  fragment TopicCarouselFragment on TopicCarouselData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment TopicListFragment on TopicListData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment TopicGridFragment on TopicGridData {
    settings {
      viewAll
    }
    config {
      topics {
        ...TopicFragment
        order
      }
    }
  }

  fragment FeaturedTopicFragment on FeaturedTopicData {
    settings {
      topicTitle
    }
    featuredTopicConfig: config {
      autoGenerateSortBy
      autoGenerate
      topic {
        ...TopicFragment
      }
    }
  }

  fragment CollectionCarouselFragment on CollectionCarouselData {
    settings {
      viewAll
      numberOfItems
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionGridFragment on CollectionGridData {
    settings {
      numberOfItems
      viewAll
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionPillCarouselFragment on CollectionPillCarouselData {
    settings {
      viewAll
    }
    config {
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment CollectionListFragment on CollectionListData {
    settings {
      separator
      numberOfItems
      viewAll
    }
    config {
      aspectRatio
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment FeaturedCollectionSliderFragment on FeaturedCollectionSliderData {
    config {
      collections {
        ...CollectionFragment
        order
      }
    }
  }

  fragment FeaturedCollectionFragment on FeaturedCollectionData {
    settings {
      numberOfItems
    }
    config {
      collection {
        ...CollectionFragment
      }
    }
  }

  fragment TagCarouselFragment on TagCarouselData {
    settings {
      viewAll
    }
    config {
      tags {
        ...TagFragment
      }
    }
  }

  fragment TagCloudFragment on TagCloudData {
    settings {
      viewAll
    }
    config {
      tags {
        ...TagFragment
      }
    }
  }

  fragment FeaturedTagFragment on FeaturedTagData {
    settings {
      tagTitle
    }
    config {
      autoGenerateSortBy
      autoGenerate
      tag {
        ...TagFragment
      }
    }
  }

  fragment CTABlockFragment on CTABlockData {
    config {
      asset {
        ...AssetFragment
      }
      primaryAction {
        asset {
          ...AssetFragment
        }
        title
        url
        type
        internalPageType
      }
      secondaryAction {
        asset {
          ...AssetFragment
        }
        title
        url
        type
        internalPageType
      }
      theme
      eventTime
      eventDate
      eventLocation
    }
  }
  fragment CTABannerWithTextFragment on CTABannerWithTextData {
    config {
      asset {
        ...AssetFragment
      }
      primaryAction {
        title
        url
        type
        internalPageType
      }
      theme
    }
  }
  fragment HeroFragment on HeroData {
    config {
      asset {
        ...AssetFragment
      }
      eventTime
      eventDate
      eventLocation
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      alignment
    }
  }

  fragment CTABannerFragment on CTABannerData {
    config {
      asset {
        ...AssetFragment
      }
    }
  }

  fragment informationFragment on InformationData {
    config {
      asset {
        url
        blurhash
      }
      theme
      imageOrientation
      imageType
      primaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
      secondaryAction {
        title
        type
        url
        internalPageType
        asset {
          ...AssetFragment
        }
      }
    }
  }

  fragment faqsFragment on FAQData {
    config {
      questions {
        question
        answer
      }
    }
  }

  fragment spaceFragment on SpacerData {
    config {
      desktop
      mobile
      tablet
      tv
    }
  }

  fragment htmlTextBlockFragment on HTMLTextBlockData {
    config {
      content
    }
  }

  query pageAdmin($id: ID!) {
    pageAdmin(where: { id: $id }, data: { applySettings: true }) {
      slug
      type
      modules {
        id
        type
        order
        title
        description
        moduleData {
          ... on VideoContinueWatchingCarouselData {
            ...VideoContinueWatchingCarouselFragment
          }
          ... on VideoCarouselData {
            ...VideoCarouselFragment
          }
          ... on VideoListData {
            ...VideoListFragment
          }
          ... on VideoGridData {
            ...VideoGridFragment
          }
          ... on FeaturedVideoData {
            ...FeaturedVideoFragment
          }
          ... on VideoPlayerData {
            ...VideoPlayerFragment
          }
          ... on FeaturedVideoSliderData {
            ...FeaturedVideoSliderFragment
          }
          ... on PodcastListData {
            ...PodcastListFragment
          }
          ... on PodcastPlayerData {
            ...PodcastPlayerFragment
          }
          ... on CollectionGridData {
            ...CollectionGridFragment
          }
          ... on CollectionCarouselData {
            ...CollectionCarouselFragment
          }
          ... on CollectionListData {
            ...CollectionListFragment
          }
          ... on FeaturedCollectionData {
            ...FeaturedCollectionFragment
          }
          ... on CollectionDetailData {
            ...CollectionDetailFragment
          }
          ... on FeaturedCollectionSliderData {
            ...FeaturedCollectionSliderFragment
          }
          ... on TopicListData {
            ...TopicListFragment
          }
          ... on TopicGridData {
            ...TopicGridFragment
          }
          ... on TopicCarouselData {
            ...TopicCarouselFragment
          }
          ... on FeaturedTopicData {
            ...FeaturedTopicFragment
          }
          ... on TagCloudData {
            ...TagCloudFragment
          }
          ... on TagCarouselData {
            ...TagCarouselFragment
          }
          ... on FeaturedTagData {
            ...FeaturedTagFragment
          }
          ... on CTABlockData {
            ...CTABlockFragment
          }
          ... on CTABannerWithTextData {
            ...CTABannerWithTextFragment
          }
          ... on HeroData {
            ...HeroFragment
          }
          ... on CTABannerData {
            ...CTABannerFragment
          }
          ... on InformationData {
            ...informationFragment
          }
          ... on FAQData {
            ...faqsFragment
          }
          ... on SpacerData {
            ...spaceFragment
          }
          ... on HTMLTextBlockData {
            ...htmlTextBlockFragment
          }
        }
      }
    }
  }
`;

export const GET_WORKSPACE_APP = gql`
  query workspaceApp($where: WorkspaceAppUniqueInput!) {
    workspaceApp(where: $where) {
      id
      url
    }
  }
`;

export const PAGE_GROUPS = gql`
  query PageGroups($filter: PageGroupFilter) {
    pageGroups(filter: $filter, sort: { sortOn: createdAt, sortBy: DESC }) {
      pageGroups {
        name
        id
      }
      count
    }
  }
`;
