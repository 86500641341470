import { CloseOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';

const Preview = ({ bgImg, children, onRemove, IsTableView }) => {
  return (
    <div className={`preview-card ${IsTableView ? 'table-view-asset' : ''}`}>
      <img alt="" src={bgImg} />
      {onRemove && (
        <button
          aria-label="Preview card btn"
          type="button"
          className="remove-icon"
          onClick={onRemove}
        >
          <CloseOutlined />
        </button>
      )}
      {children}
    </div>
  );
};

Preview.Title = ({ className = 'title-line-clamp', color, children }) => {
  return (
    <Typography.Text
      title={children}
      className={`${className}`}
      style={{
        color
      }}
    >
      {children}
    </Typography.Text>
  );
};

export default Preview;
