import { Col, Row } from 'antd';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import VideoCard from './VideoCard';

function VideoGrid({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  responsive
}) {
  return (
    <section className={`video-grid ${responsive ? 'responsive-view' : ''}`}>
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
        <Row
          gutter={[
            { xs: 8, sm: 8, md: responsive ? 8 : 16, lg: responsive ? 8 : 16 },
            {
              xs: 16,
              sm: 16,
              md: responsive ? 16 : 32,
              lg: responsive ? 16 : 32
            }
          ]}
        >
          {data?.map((item) => {
            return (
              <Col
                key={item?.id}
                xs={{ span: 12 }}
                md={{ span: responsive ? 12 : 8 }}
                lg={{ span: responsive ? 12 : 6 }}
              >
                <VideoCard
                  src={item?.src}
                  blurHash={item.blurHash}
                  defaultValue={item?.defaultValue}
                  time={item?.time}
                  category={item?.category}
                  collection={item?.collection}
                  title={item?.title}
                  date={item?.date}
                  tags={item?.tags}
                  tagBackground={item?.tagBackground}
                  tagTextColor={item?.tagTextColor}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}
export default VideoGrid;
