import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_OPTIONS,
  AUTO_GENERATED_SORT_BY_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES,
  TEMPLATE_OPTION,
  getAutoGenerateByDataValue,
  getAutoGeneratedByIdProps
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { SelectPodcasts } from '../../../../labels/collections/components/FormInputs';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const AUTO_GENERATED_SORT_BY_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM },
  { label: 'Plays', value: AUTO_GENERATED_SORT_BY_TYPES.PLAYS }
];

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  isDefaultModule: false,
  settings: {
    title: true,
    description: true,
    viewAll: true,
    podcastTimestamp: true,
    podcastDate: true,
    podcastOrder: true,
    imageThumbnail: true,
    podcastProgressBar: true,
    separator: true,
    podcastDescription: true,
    carouselIndicator: true,
    podcastCTA: true,
    collectionNames: true
  },
  config: {
    autoGenerate: false,
    autoGenerateByType: null,
    autoGenerateById: null,
    autoGenerateSortBy: null,
    autoGenerateLimit: null,
    podcasts: [MODULE_TYPES.PODCAST_PLAYER].includes(type)
      ? { id: '', url: '', title: '' }
      : []
  }
});

const MODULE_KEYS = {
  [MODULE_TYPES.PODCAST_LIST]: 'podcastListModule',
  [MODULE_TYPES.PODCAST_PLAYER]: 'podcastPlayerModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.PODCAST_LIST]: 'Podcast List Configs',
  [MODULE_TYPES.PODCAST_PLAYER]: 'Podcast Player Configs'
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.PODCAST_LIST]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.PODCAST_LIST]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [MODULE_TYPES.PODCAST_LIST]
  },
  {
    name: 'podcastAsk',
    label: 'Podcast Ask',
    allowedTypes: [MODULE_TYPES.PODCAST_PLAYER]
  },
  {
    name: 'podcastShare',
    label: 'Podcast Share',
    allowedTypes: [MODULE_TYPES.PODCAST_PLAYER]
  },
  {
    name: 'podcastDescription',
    label: 'Podcast Description',
    allowedTypes: [MODULE_TYPES.PODCAST_PLAYER, MODULE_TYPES.PODCAST_LIST]
  },
  {
    name: 'podcastTimestamp',
    label: 'Timestamp',
    allowedTypes: [MODULE_TYPES.PODCAST_PLAYER, MODULE_TYPES.PODCAST_LIST]
  },
  {
    name: 'podcastDate',
    label: 'Date',
    allowedTypes: [MODULE_TYPES.PODCAST_PLAYER, MODULE_TYPES.PODCAST_LIST]
  },
  {
    name: 'podcastOrder',
    label: 'Order in Collection',
    allowedTypes: [MODULE_TYPES.PODCAST_PLAYER, MODULE_TYPES.PODCAST_LIST]
  },
  {
    name: 'imageThumbnail',
    label: 'Thumbnail Image',
    allowedTypes: [MODULE_TYPES.PODCAST_LIST]
  },
  {
    name: 'podcastProgressBar',
    label: 'Progress Bar',
    allowedTypes: [MODULE_TYPES.PODCAST_LIST]
  },
  {
    name: 'separator',
    label: 'Separator',
    allowedTypes: [MODULE_TYPES.PODCAST_LIST]
  }
];

const { PODCAST } = PAGE_TYPES;
const REST_PAGE_TYPES = [
  PAGE_TYPES.TOPIC,
  PAGE_TYPES.TAG,
  PAGE_TYPES.COLLECTION
];
const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.PODCAST_LIST]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.PODCAST_PLAYER]: [PODCAST]
};

const PodcastForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType,
  pageId
}) => {
  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const configProps = Form.useWatch(['config'], form);
  const { autoGenerateByType, autoGenerate } =
    configProps ?? initialValues.config;
  const isMultiPodcast = ![MODULE_TYPES.PODCAST_PLAYER].includes(type);

  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);
  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  const includeOptions = useMemo(
    () => (autoGenerateByType === pageType ? [TEMPLATE_OPTION] : []),
    [autoGenerateByType, pageType]
  );

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          ...(type !== MODULE_TYPES.PODCAST_PLAYER && {
            autoGenerate:
              defaultValues?.moduleData?.config?.autoGenerate ?? false,
            autoGenerateByType:
              defaultValues?.moduleData?.config?.autoGenerateByType || null,
            autoGenerateById: idm
              ? TEMPLATE_OPTION
              : getAutoGenerateByDataValue[
                  defaultValues?.moduleData?.config?.autoGenerateByType
                ]?.(defaultValues?.moduleData?.config?.autoGenerateByData) ??
                null,
            autoGenerateSortBy:
              defaultValues?.moduleData?.config?.podcastAutoGenerateSortBy ||
              null,
            autoGenerateLimit:
              defaultValues?.moduleData?.config?.autoGenerateLimit || null
          }),
          podcasts: isMultiPodcast
            ? defaultValues?.moduleData?.config?.podcasts?.map(
                ({ id, title, imageThumbnail }) => ({
                  id,
                  title,
                  url: imageThumbnail?.url ?? ''
                })
              ) || []
            : {
                id: defaultValues?.moduleData?.config?.podcast?.id ?? '',
                title: defaultValues?.moduleData?.config?.podcast?.title ?? '',
                url:
                  defaultValues?.moduleData?.config?.podcast?.imageThumbnail
                    ?.url ?? ''
              }
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    isDefaultModule: defaultModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const isDefault = allowedTemplateConfig && defaultModule;
    const {
      podcasts,
      autoGenerate: generate,
      autoGenerateById,
      autoGenerateLimit,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          autoGenerate: generate,
          autoGenerateById: autoGenerateById?.value,
          ...restConfig,
          ...(isDefault
            ? {
                podcastId: '*'
              }
            : {
                ...(!generate &&
                  isMultiPodcast && {
                    podcasts:
                      podcasts?.map(({ id }, i) => ({
                        podcastId: id,
                        order: i + 1
                      })) ?? []
                  }),
                ...(!generate &&
                  !isMultiPodcast && {
                    podcastId: podcasts?.id ?? ''
                  })
              }),
          ...(autoGenerateLimit && {
            autoGenerateLimit: Number(autoGenerateLimit)
          })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleSubmit}
      form={form}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          {allowedTemplateConfig && pageType === PODCAST && (
            <>
              <Typography.Text>Template Config</Typography.Text>
              <Form.Item
                className="m-0"
                name="isDefaultModule"
                valuePropName="checked"
              >
                <Switch label="Use Template Data" />
              </Form.Item>
            </>
          )}
          {(!allowedTemplateConfig ||
            (allowedTemplateConfig && !isDefaultModule)) && (
            <>
              <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
              <div>
                {type !== MODULE_TYPES.PODCAST_PLAYER && (
                  <>
                    <Form.Item
                      name={['config', 'autoGenerate']}
                      valuePropName="checked"
                    >
                      <Switch
                        label={
                          isMultiPodcast
                            ? 'Auto Generated Podcasts'
                            : 'Auto Generated Podcast'
                        }
                      />
                    </Form.Item>
                    {autoGenerate && (
                      <>
                        <Form.Item
                          label="Auto Generated By Type"
                          name={['config', 'autoGenerateByType']}
                        >
                          <AntdSelect
                            options={AUTO_GENERATED_OPTIONS}
                            placeholder="Select type"
                            onChange={() => {
                              form.setFieldValue(
                                ['config', 'autoGenerateById'],
                                null
                              );
                            }}
                          />
                        </Form.Item>
                        {autoGenerateByType && autoGenerateByIdProps && (
                          <Form.Item
                            label="Auto Generated By"
                            name={['config', 'autoGenerateById']}
                          >
                            <Select
                              placeholder="Select"
                              query={autoGenerateByIdProps?.query}
                              dataSelector={autoGenerateByIdProps?.dataSelector}
                              keys={autoGenerateByIdProps?.keys}
                              {...(allowedTemplateConfig && {
                                includeOptions
                              })}
                            />
                          </Form.Item>
                        )}
                        <Form.Item
                          label="Auto Generated Sort By"
                          name={['config', 'autoGenerateSortBy']}
                        >
                          <AntdSelect
                            options={AUTO_GENERATED_SORT_BY_OPTIONS}
                            placeholder="Select sort by"
                          />
                        </Form.Item>
                        {isMultiPodcast && (
                          <Form.Item
                            label="No. of Auto generated Podcasts"
                            name={['config', 'autoGenerateLimit']}
                            rules={[
                              formValidatorRules?.number,
                              formValidatorRules?.maxNumberAllowed(
                                configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                              )
                            ]}
                          >
                            <Input placeholder="Enter number" />
                          </Form.Item>
                        )}
                      </>
                    )}
                  </>
                )}
                {!autoGenerate && (
                  <Form.Item
                    label={
                      isMultiPodcast ? 'Select Podcasts' : 'Select Podcast'
                    }
                    name={['config', 'podcasts']}
                    extra={
                      isMultiPodcast ? 'Select items in order you want' : ''
                    }
                  >
                    <SelectPodcasts multiple={isMultiPodcast} />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default PodcastForm;
