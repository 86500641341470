import React from 'react';
import { useParams } from 'react-router-dom';
import { MODULE_TYPES } from '../../../../common/constants';
import FormLayout from '../../../component/layout/FormLayout';
import { FORM_TYPES, useEditPage } from '../../context/EditPageProvider';
import { usePagePreview } from '../../context/PreviewPageProvider';
import AddModule from './AddModule';
import { PlaceHolderComponents } from './LeftPanel';
import ArticleForm from './moduleForms/ArticleForm';
import CTAForm from './moduleForms/CTAForm';
import CTAGridCarouselForm from './moduleForms/CTAGridCarouselForm';
import CollectionForm from './moduleForms/CollectionForm';
import FAQForm from './moduleForms/FAQForm';
import FormModule from './moduleForms/FormModule';
import HtmlTextForm from './moduleForms/HtmlTextForm';
import InformationForm from './moduleForms/InformationForm';
import PodcastForm from './moduleForms/PodcastForm';
import Settings from './moduleForms/Settings';
import SpacerForm from './moduleForms/SpacerForm';
import SubscriptionForm from './moduleForms/SubscriptionForm';
import TagForm from './moduleForms/TagForm';
import TopicForm from './moduleForms/TopicForm';
import VideoForm from './moduleForms/VideoForm';

const Modules = {
  [MODULE_TYPES.SETTINGS]: Settings,
  [MODULE_TYPES.ADD]: AddModule,
  // Videos
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]: VideoForm,
  [MODULE_TYPES.VIDEO_CAROUSEL]: VideoForm,
  [MODULE_TYPES.VIDEO_LIST]: VideoForm,
  [MODULE_TYPES.VIDEO_GRID]: VideoForm,
  [MODULE_TYPES.FEATURED_VIDEO]: VideoForm,
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: VideoForm,
  [MODULE_TYPES.VIDEO_PLAYER]: VideoForm,
  // Collections
  [MODULE_TYPES.COLLECTION_CAROUSEL]: CollectionForm,
  [MODULE_TYPES.COLLECTION_GRID]: CollectionForm,
  [MODULE_TYPES.COLLECTION_LIST]: CollectionForm,
  [MODULE_TYPES.FEATURED_COLLECTION]: CollectionForm,
  [MODULE_TYPES.FEATURED_COLLECTION_SLIDER]: CollectionForm,
  [MODULE_TYPES.COLLECTION_PILL_CAROUSEL]: CollectionForm,
  [MODULE_TYPES.COLLECTION_DETAIL]: CollectionForm,
  // Tags
  [MODULE_TYPES.TAG_CAROUSEL]: TagForm,
  [MODULE_TYPES.TAG_CLOUD]: TagForm,
  [MODULE_TYPES.FEATURED_TAG]: TagForm,
  // Topics
  [MODULE_TYPES.TOPIC_CAROUSEL]: TopicForm,
  [MODULE_TYPES.TOPIC_LIST]: TopicForm,
  [MODULE_TYPES.TOPIC_GRID]: TopicForm,
  [MODULE_TYPES.FEATURED_TOPIC]: TopicForm,
  // CTA
  [MODULE_TYPES.CTA_BLOCK]: CTAForm,
  [MODULE_TYPES.CTA_BANNER_WITH_TEXT]: CTAForm,
  [MODULE_TYPES.CTA_BANNER]: CTAForm,
  [MODULE_TYPES.HERO]: CTAForm,
  [MODULE_TYPES.CTA_BUTTON_LIST]: CTAGridCarouselForm,
  // Article
  [MODULE_TYPES.ARTICLE_LIST]: ArticleForm,
  // todo : commented for future use
  // Donation
  // [MODULE_TYPES.DONATION]: DonationForm,
  // Information
  [MODULE_TYPES.INFORMATION]: InformationForm,
  // FAQs
  [MODULE_TYPES.FAQ]: FAQForm,
  // Spacer
  [MODULE_TYPES.SPACER]: SpacerForm,
  // Html Text
  [MODULE_TYPES.HTML_TEXT_BLOCK]: HtmlTextForm,
  // Podcast
  [MODULE_TYPES.PODCAST_LIST]: PodcastForm,
  [MODULE_TYPES.PODCAST_PLAYER]: PodcastForm,
  // Form
  [MODULE_TYPES.FORM_CAROUSEL]: FormModule,
  [MODULE_TYPES.FORM_DISPLAY]: FormModule,

  // Subscription
  [MODULE_TYPES.SUBSCRIPTION_BLOCK]: SubscriptionForm,
  [MODULE_TYPES.SUBSCRIPTION_HEADER]: SubscriptionForm,
  [MODULE_TYPES.SUBSCRIPTION_LIST]: SubscriptionForm
};

const RightPanel = () => {
  const {
    selectionType,
    form,
    clearRightPanel,
    updateModule,
    deleteModule,
    isDefaultPage,
    pageType
  } = useEditPage();
  const { refetch } = usePagePreview();
  const { id } = useParams();

  const handleCancel = () => {
    if (form.type === FORM_TYPES.ADD) {
      deleteModule(form.index);
    }
    clearRightPanel();
  };

  const handleSuccess = (data) => {
    updateModule(data);
    clearRightPanel();
    refetch();
  };

  if (!selectionType) return <FormLayout />;

  const FormComponent = Modules[selectionType];
  if (!FormComponent) return <FormLayout />;

  if (selectionType === MODULE_TYPES.ADD)
    return (
      <FormLayout>
        <FormComponent />
      </FormLayout>
    );

  return (
    <FormLayout title={PlaceHolderComponents?.[selectionType]?.title}>
      <FormComponent
        key={selectionType}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
        pageId={id}
        form={form}
        type={selectionType}
        isDefaultPage={isDefaultPage}
        pageType={pageType}
      />
    </FormLayout>
  );
};

export default RightPanel;
