import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  ARTICLE_ACTION_TYPE,
  MODULE_TYPES,
  PAGE_TYPES,
  STATUS_TYPES
} from '../../../../../common/constants';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const CONFIG_TITLE = {
  [MODULE_TYPES.ARTICLE_LIST]: 'Article List Configs'
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.ARTICLE_LIST]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.ARTICLE_LIST]
  }
];

const getInitialValues = () => ({
  title: '',
  description: '',
  permissions: [],
  status: STATUS_TYPES.PUBLISHED,
  isDefaultModule: false,
  settings: {
    title: true,
    description: true
  },
  config: {
    actionName: '',
    actionType: ARTICLE_ACTION_TYPE[0].value
  }
});

const { ARTICLE } = PAGE_TYPES;
const REST_PAGE_TYPES = [
  PAGE_TYPES.TOPIC,
  PAGE_TYPES.TAG,
  PAGE_TYPES.COLLECTION
];

const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.ARTICLE_LIST]: [...REST_PAGE_TYPES]
};

const MODULE_KEYS = {
  [MODULE_TYPES.ARTICLE_LIST]: 'articleListModule'
};

function ArticleForm({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType
}) {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);

  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);

  const initialValues = useMemo(() => getInitialValues(type), [type]);
  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      const values = {
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          actionType:
            defaultValues?.moduleData?.config?.actionType ??
            ARTICLE_ACTION_TYPE[0].value,
          actionName: defaultValues?.moduleData?.config?.actionName ?? ''
        }
      };
      form.setFieldsValue(values);
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues, type]);

  const handleSubmit = (values) => {
    // eslint-disable-next-line no-shadow
    const { config, settings, permissions, isDefaultModule, ...rest } =
      values ?? {};
    const key = MODULE_KEYS[type];

    const { ...restConfig } = config ?? {};

    if (!key) return;
    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...restConfig
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <ModuleFields />
        <Space className="w-full" direction="vertical">
          <Space className="w-full" direction="vertical">
            <ShowFields settings={SETTINGS} type={type} />
            {allowedTemplateConfig && pageType === ARTICLE && (
              <>
                <Typography.Text>Template Config</Typography.Text>
                <Form.Item
                  className="m-0"
                  name="isDefaultModule"
                  valuePropName="checked"
                >
                  <Switch label="Use Template Data" />
                </Form.Item>
              </>
            )}
            {(!allowedTemplateConfig ||
              (allowedTemplateConfig && !isDefaultModule)) && (
              <>
                <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
              </>
            )}
            <Form.Item label="Action Name" name={['config', 'actionName']}>
              <Input placeholder="Enter action name" />
            </Form.Item>
            <Form.Item label="Action Type" name={['config', 'actionType']}>
              <AntdSelect
                options={ARTICLE_ACTION_TYPE?.map(({ name, value }) => ({
                  label: name,
                  value
                }))}
                placeholder="Select Action Type"
              />
            </Form.Item>
            <Permissions />
            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                    disabled={loading}
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled={loading}
                    onClick={onCancel}
                    type="text"
                    className="text-btn2"
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Space>
        </Space>
      </Form>
    </>
  );
}

export default ArticleForm;
