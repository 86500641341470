import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';
import { isArray, isFunction, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ASSET_CATEGORY,
  AUDIO_SERVICE_TYPES,
  AUDIO_UPLOAD_PLATFORMS,
  DOMAIN_STATUS,
  MAX_LENGTHS,
  PROVIDER_TYPES,
  REGEX,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION,
  allowedAudio,
  allowedImages
} from '../../../common/constants';
import {
  Blurhash,
  fileUpload,
  formValidatorRules,
  uploadToPlatform
} from '../../../common/utils';
import { messageContext } from '../../../components/AppComponentContainer';
import LoaderComponent from '../../../components/LoaderComponent';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { SelectPlatForm } from '../../videos/components/FormInputs';
import {
  CREATE_ASSET,
  CREATE_AUDIO_ASSET,
  UPDATE_ASSET_STATUS,
  UPDATE_AUDIO_ASSET,
  UPDATE_AUDIO_ASSET_STATUS
} from '../graphql/Mutations';
import { GET_ASSET, GET_UPLOAD_SIGNED_URL } from '../graphql/Queries';

const { TextArea } = Input;

const CommonAudioForm = ({ isModal, setShowModal, setNewAsset }) => {
  const location = useLocation();
  const { audioId, isUpdate } = location?.state || {};
  const [form] = Form?.useForm();
  const [audioData, setAudioData] = useState({});
  const [audioLoading, setAudioLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [audioProgress, setAudioProgress] = useState(undefined);
  const audioValue = Form?.useWatch('audio', form);
  const audioUrl = Form?.useWatch('audioUrl', form);
  const peerTubeAudioValue = Form?.useWatch('peerTubeAudio', form);
  const audioPlatform = Form?.useWatch('audioPlatform', form);

  const [fetchAudio] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: audioId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setAudioLoading(true);
      setAudioData(res?.asset);
      setAudioLoading(false);
    },
    onError: () => {
      setAudioLoading(false);
    }
  });

  useEffect(() => {
    if (audioId) {
      fetchAudio();
    } else {
      setAudioLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioId]);

  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL);
  const [createAssetMutate] = useMutation(CREATE_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [createAudioAsset] = useMutation(CREATE_AUDIO_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateAudioAssetMutate] = useMutation(UPDATE_AUDIO_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });

  const [updateAudioAssetStatus] = useMutation(UPDATE_AUDIO_ASSET_STATUS);

  const [updateAssetStatus] = useMutation(UPDATE_ASSET_STATUS);

  const handleCancel = () => {
    if (isModal) {
      setShowModal(false);
      setNewAsset(null);
    } else {
      history?.replace(ROUTES?.AUDIO);
    }
  };

  const createImageAsset = async (values) => {
    const { thumbImage, title, description, thumbnailStoragePlatform } = values;

    try {
      const textData = {
        title,
        description
      };
      let key = null;
      let blurhash = null;
      let contentType = null;

      if (thumbImage?.length > 0) {
        const imageFile = thumbImage?.[0]?.originFileObj;
        const fileName = thumbImage?.[0]?.name;
        contentType = thumbImage?.[0]?.type;
        const getSignedPutUrlResult = await getSignedUrl({
          variables: {
            data: {
              fileName: fileName?.replace(/ /g, '_'),
              contentType,
              assetType: ASSET_CATEGORY?.IMAGE,
              platformId: thumbnailStoragePlatform?.value
            }
          }
        });
        if (getSignedPutUrlResult?.data?.getAssetUploadSignedUrl) {
          await fileUpload(
            getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.signedUrl,
            imageFile
          );
          key = getSignedPutUrlResult?.data?.getAssetUploadSignedUrl?.key;
          blurhash = await Blurhash?.encode(imageFile);
        }
      }
      const media = {
        ...textData,
        categoryKey: ASSET_CATEGORY?.IMAGE,
        contentType,
        key,
        blurhash,
        platformId: thumbnailStoragePlatform?.value
      };
      if (!key) {
        delete media?.key;
      }
      if (!blurhash) {
        delete media?.blurhash;
      }
      const response = await createAssetMutate({
        variables: {
          data: {
            ...media
          }
        }
      });
      if (response?.data?.createAsset) {
        return response;
      }
    } catch (error) {
      setButtonLoading(false);
      return Promise.reject(error);
    }
  };

  const onFinish = async (values) => {
    if (isFunction(setNewAsset)) {
      setNewAsset(null);
    }
    setButtonLoading(true);
    const {
      audio,
      thumbImage,
      peerTubeAudio,
      title,
      description,
      audioPlatform: platform
    } = values;
    const textData = {
      title,
      description
    };
    let audioFile;
    try {
      if (isUpdate) {
        updateAudioAssetMutate({
          variables: {
            where: { id: audioId },
            data: textData
          }
        })
          .then(() => {
            history?.replace(ROUTES?.AUDIO);
            setButtonLoading(false);
          })
          .catch(() => {
            setAudioProgress(undefined);
            setButtonLoading(false);
          });
      } else {
        const importUrl = values?.audioUrl;
        let imageUploadRes;
        audioFile =
          peerTubeAudio?.[0]?.originFileObj || audio?.[0]?.originFileObj;
        // peertube with thumbnails
        if (thumbImage?.length > 0) {
          imageUploadRes = await createImageAsset(values);
        }
        const response = await createAudioAsset({
          variables: {
            data: {
              ...textData,
              platformId: platform?.value,
              // change service value when there is more providers available other than peertube
              service: AUDIO_SERVICE_TYPES?.PEERTUBE,
              ...(imageUploadRes && {
                imageAssetId: imageUploadRes?.data?.createAsset?.asset?.id
              }),
              ...(importUrl
                ? {
                    importUrl
                  }
                : {
                    contentLength: audioFile?.size,
                    fileName: `${audioFile?.name?.replace(/\s/g, '_')}`
                  })
            }
          }
        });
        if (importUrl && response?.data?.createAudioAsset) {
          setAudioProgress(undefined);
          setButtonLoading(false);
          await updateAudioAssetStatus({
            variables: {
              peertubeUUID: response?.data?.createAudioAsset?.uploadId,
              id: response?.data?.createAudioAsset?.asset?.id,
              platformId: platform?.value
            }
          });
          if (response?.data?.createAudioAsset)
            if (isModal) {
              const asset = response?.data?.createAudioAsset?.asset;
              setShowModal(false);
              setNewAsset({ asset: true, data: asset });
            } else {
              history?.replace(ROUTES?.AUDIO);
            }
          return;
        }
        if (response?.data?.createAudioAsset?.signedUrl) {
          setAudioProgress(0);
          let res;
          // peertube scenario
          if (peerTubeAudio?.length > 0) {
            res = await uploadToPlatform(
              response?.data?.createAudioAsset?.signedUrl,
              audioFile,
              setAudioProgress,
              platform?.provider?.key
            ).catch(async (err) => {
              await updateAssetStatus({
                variables: {
                  where: {
                    id: response?.data?.createAudioAsset?.asset?.id
                  },

                  data: {
                    errorObj: JSON.parse(err),

                    status: DOMAIN_STATUS.ERRORED
                  }
                }
              });
              setAudioProgress(undefined);
              setButtonLoading(false);
              history?.replace(ROUTES?.AUDIO);
            });
            if (
              res &&
              response?.data?.createAudioAsset?.asset?.id &&
              peerTubeAudio?.length > 0
            ) {
              if (platform?.provider?.key === AUDIO_UPLOAD_PLATFORMS.PEERTUBE) {
                const data = JSON?.parse(res);
                await updateAudioAssetStatus({
                  variables: {
                    peertubeUUID: data.video?.uuid,
                    id: response?.data?.createAudioAsset?.asset?.id,
                    platformId: platform?.value
                  }
                });
              }
              if (response?.data?.createAudioAsset?.asset)
                if (isModal) {
                  const asset = response?.data?.createAudioAsset?.asset;
                  setShowModal(false);
                  setNewAsset({ asset: true, data: asset });
                } else {
                  history?.replace(ROUTES?.AUDIO);
                }
              setAudioProgress(undefined);
              setButtonLoading(false);
            }
            // normal audio upload scenario
          } else {
            await fileUpload(
              response?.data?.createAudioAsset?.signedUrl,
              audioFile,
              setAudioProgress
            );
            if (response?.data?.createAudioAsset?.asset)
              if (isModal) {
                const asset = response?.data?.createAudioAsset?.asset;
                setShowModal(false);
                setNewAsset({ asset: true, data: asset });
              } else {
                history?.replace(ROUTES?.AUDIO);
              }
            setAudioProgress(undefined);
            setButtonLoading(false);
          }
        }
      }
    } catch (error) {
      setAudioProgress(undefined);
      setButtonLoading(false);
    }
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const initialValues = {
    ...audioData
  };

  if (audioLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Form
        form={form}
        initialValues={initialValues}
        name="create-asset"
        layout="vertical"
        className="add-edit-form"
        onFinish={onFinish}
        disabled={isViewOnly}
      >
        <Form.Item
          name="title"
          label="Title"
          required
          rules={[
            formValidatorRules?.required('Please enter title!'),
            formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
          ]}
        >
          <Input
            placeholder="Enter title"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
        >
          <TextArea
            rows={2}
            placeholder="Enter description"
            disabled={isViewOnly || buttonLoading}
          />
        </Form.Item>
        {!isUpdate && (
          <>
            <div className="text-label">Upload to 3rd Party</div>
            <Form.Item
              name="audioPlatform"
              label="Audio Platform"
              rules={[
                {
                  required: peerTubeAudioValue?.length,
                  message: 'Please select platform'
                }
              ]}
            >
              <SelectPlatForm
                placeholder="Select audio platform"
                type={PROVIDER_TYPES.AUDIO}
                fullWidth
              />
            </Form.Item>
            {audioPlatform?.provider?.key ===
              AUDIO_UPLOAD_PLATFORMS.PEERTUBE && (
              <>
                <Form.Item
                  name="peerTubeAudio"
                  label="Audio"
                  rules={[
                    {
                      async validator(_, value) {
                        if (
                          !value?.length &&
                          !audioUrl &&
                          !audioValue?.length
                        ) {
                          throw new Error('Please select audio');
                        }
                      }
                    }
                  ]}
                  normalize={(value) => {
                    const name = value?.[0]?.originFileObj?.name;
                    const ext = name?.substring(name?.lastIndexOf('.') + 1);
                    if (allowedAudio?.includes(ext)) {
                      return value;
                    }
                    return [];
                  }}
                  getValueFromEvent={(e) => {
                    if (isArray(e)) {
                      return e;
                    }
                    return e?.fileList;
                  }}
                  valuePropName="fileList"
                >
                  <Upload
                    maxCount={1}
                    disabled={buttonLoading}
                    accept={map(allowedAudio, (item) => `.${item}`)?.join(', ')}
                    beforeUpload={() => false}
                    onChange={() => form?.resetFields(['audio', 'audioUrl'])}
                  >
                    <Space>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                      <span>
                        ({map(allowedAudio, (item) => `.${item}`)?.join(', ')})
                      </span>
                    </Space>
                  </Upload>
                </Form.Item>
                <div className="input-divider">Or</div>
                <Form.Item
                  rules={[
                    {
                      async validator(_, value) {
                        if (
                          !value?.length &&
                          !audioValue?.length &&
                          !peerTubeAudioValue?.length
                        ) {
                          throw new Error('Please enter url');
                        }
                        if (value && !REGEX?.WEB_URL?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          throw new Error('Should be a valid URL');
                        }
                      }
                    }
                  ]}
                  name="audioUrl"
                  label="Import Audio"
                >
                  <Input
                    onChange={() =>
                      form?.resetFields([
                        'audio',
                        'peerTubeAudio',
                        'thumbImage'
                      ])
                    }
                    placeholder="Enter url"
                    disabled={isViewOnly || buttonLoading}
                  />
                </Form.Item>
                <Form.Item
                  name="thumbnailStoragePlatform"
                  label="Thumbnail Image Platform"
                >
                  <SelectPlatForm
                    placeholder="Select thumbnail image platform"
                    type={PROVIDER_TYPES.STORAGE}
                    fullWidth
                  />
                </Form.Item>
                <Form.Item
                  name="thumbImage"
                  label="Thumbnail Image"
                  normalize={(value) => {
                    const { name, size } = value?.[0]?.originFileObj || {};
                    const ext = name?.substring(name?.lastIndexOf('.') + 1);
                    const isLt4Mb = size / 1024 / 1024 < 4;
                    if (allowedImages?.includes(ext) && isLt4Mb) {
                      return value;
                    }
                    if (!isLt4Mb && name && size) {
                      messageContext?.error(
                        'image size should be less than 4 mb!'
                      );
                    }
                    return [];
                  }}
                  getValueFromEvent={(e) => {
                    if (isArray(e)) {
                      return e;
                    }
                    return e?.fileList;
                  }}
                  valuePropName="fileList"
                >
                  <Upload
                    maxCount={1}
                    disabled={buttonLoading}
                    accept={map(allowedImages, (item) => `.${item}`)?.join(
                      ', '
                    )}
                    beforeUpload={() => false}
                    onChange={() => form?.resetFields(['audio', 'audioUrl'])}
                  >
                    <Space>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                      <span>
                        ({map(allowedImages, (item) => `.${item}`)?.join(', ')}
                        &nbsp;&amp; &lt; 4MB )
                      </span>
                    </Space>
                  </Upload>
                </Form.Item>
              </>
            )}
          </>
        )}
        {audioProgress >= 0 && (
          <Form.Item>
            <ProgressBar progress={audioProgress} />
          </Form.Item>
        )}

        <div className="d-flex button-section">
          <Space>
            {isAddEditAllowed && (
              <Form.Item>
                <Button
                  loading={buttonLoading}
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                >
                  Save
                </Button>
              </Form.Item>
            )}
            <Form.Item>
              <Button
                type="text"
                className="text-btn2"
                disabled={buttonLoading}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </>
  );
};

export default CommonAudioForm;
