import { gql } from '@apollo/client';

export const COMMON_SUBSCRIPTION_FIELDS = gql`
  fragment SubscriptionPlan on SubscriptionPlan {
    id
    key
    order
    weight
    name
    description
    html
    workspaceId
    type
    frequency
    allowCustomAmount
    amount {
      usd {
        price
        maxPrice
      }
    }
    badges {
      createdAt
      id
      image {
        id
        url
        blurhash
      }
      isActive
      isSelected
      key
      name
      workspaceId
    }
    images {
      id
      url
      blurhash
    }
    refData
    metaData
    permissions {
      key
      allowed
      applyLimit
      errorMessage
      errorTitle
    }
    isActive
    isDefault
    showOnPaywall
    createdAt
    updatedAt
    applyPermissions
  }
`;

export const GET_SUBSCRIPTION_PLANS = gql`
  ${COMMON_SUBSCRIPTION_FIELDS}
  query subscriptionPlans(
    $filter: SubscriptionPlansFilter
    $sort: SubscriptionPlansSort
  ) {
    subscriptionPlans(filter: $filter, sort: $sort) {
      count
      subscriptionPlans {
        ...SubscriptionPlan
      }
    }
  }
`;

export const SUBSCRIPTION_PERMISSIONS = gql`
  query subscriptionPlanPermissions {
    subscriptionPlanPermissions {
      description
      group
      id
      key
      type
    }
  }
`;

export const GET_SUBSCRIPTION_PLAN = gql`
  ${COMMON_SUBSCRIPTION_FIELDS}
  query subscriptionPlan($where: SubscriptionPlanWhereInput!) {
    subscriptionPlan(where: $where) {
      ...SubscriptionPlan
    }
  }
`;

export const SUBSCRIPTION_BADGES = gql`
  query BadgesAdmin($filter: BadgesFilter, $sort: BadgesSort) {
    badgesAdmin(filter: $filter, sort: $sort) {
      badges {
        createdAt
        id
        image {
          id
          url
          blurhash
        }
        isActive
        isSelected
        key
        name
        workspaceId
      }
    }
  }
`;
