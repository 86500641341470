import JoditEditor from 'jodit-react';
import 'jodit/es2021/jodit.min.css';
import React, { useEffect, useState } from 'react';
import './styles/joditEditor.less';

const HtmlEditor = ({
  className,
  disabled,
  value,
  onChange,
  height = '100%',
  ...rest
}) => {
  const [editorContent, setEditorContent] = useState(value);

  useEffect(() => {
    if (value) setEditorContent(value);
  }, [value]);

  const handleEditorChange = (newContent) => {
    setEditorContent(newContent);
    if (onChange) {
      onChange(newContent);
    }
  };

  return (
    <JoditEditor
      value={editorContent}
      config={{
        buttons: [
          'paragraph',
          'bold',
          'underline',
          'italic',
          'strikethrough',
          'superscript',
          'subscript',
          'eraser',
          'outdent',
          'indent',
          'align',
          'ol',
          'ul',
          'link',
          'fullsize',
          'source',
          'preview',
          'undo',
          'redo'
        ],
        toolbarAdaptive: false,
        toolbarButtonSize: 'large',
        disabled,
        height,
        allowResizeX: false,
        allowResizeY: false,
        className,
        theme: 'dark'
      }}
      onBlur={(val) => {
        if (val === '<p><br></p>') handleEditorChange('');
        else handleEditorChange(val);
      }}
      {...rest}
    />
  );
};

export default HtmlEditor;
